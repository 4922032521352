import React from 'react';
import { Route, Redirect } from "react-router-dom";
import { isAuthenticated } from "./Auth";


export const PrivateRoute = ({ component: Component, ...rest}) =>(
    <Route {...rest} render= {props =>(
        <React.Fragment>
            {isAuthenticated().status
            ? (
                <Component {...props} />
            ) 
            : (
                <React.Fragment>
                    <Redirect to={{ pathname: '/login', state: { from: props.location}}} />
                </React.Fragment>
            )}
        </React.Fragment>
    )}/>
)