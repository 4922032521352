import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { api } from '../../../services/api';

import { AiOutlineClose } from "react-icons/ai";

function NewDeclarationTemplateModal ({isOpen, onRequestClose}){
    const [btnBlocked, setBtnBlocked] = useState(true);

    const [message, setMessage] = useState('');
    const [nameTemplate, setNameTemplate] = useState('');
    const [imageTemplate, setImageTemplate] = useState();
    const [headerTemplate, setHeaderTemplate] = useState('');
    const [signatureTemplate, setSignatureTemplate] = useState('');

    useEffect(() => {
        if(message === '' || imageTemplate === null || nameTemplate === '' || headerTemplate === '' || signatureTemplate === ''){
            setBtnBlocked(true);
        }else {
            setBtnBlocked(false);
        }
        
    }, [message, imageTemplate, nameTemplate, headerTemplate, signatureTemplate]);

    async function createTemplate(){
        try {
            var formData = new FormData();
            formData.append("message", message);
            formData.append("files", imageTemplate);
            formData.append("name", nameTemplate);
            formData.append("header", headerTemplate);
            formData.append("signature", signatureTemplate);

            var config = {
                headers: {
                    'Content-Type': "multipart/form-data; boundary='files'"
                }
            };
            
            await api.post('/templated', formData,  config)
                .catch(function  (error){
                    console.log(error.response);
                }).then(data => {
                    onRequestClose(true, true, data.data.template);
                });
            
        } catch (error) {
            let msg_error = undefined;
            
            if (error && error.response && error.response.data) {
                const errorData = error.response.data;
                if (typeof errorData.error === 'string') {
                  msg_error = errorData.error;
                }
            }

            onRequestClose(true, false, msg_error);
        }

    }

    return (
        <Modal
            ariaHideApp={false}
            overlayClassName="react-modal-overlay"
            className="react-modal-content"
            isOpen={isOpen} 
            onRequestClose={onRequestClose}
        >
            <button 
                className="react-modal-close"
                onClick={onRequestClose} 
            >
                <AiOutlineClose />
            </button>
            <div className="react-modal-title">
                <h4 id="modal-title">Cadastrar Template de Declaração de Matrícula</h4>
            </div>

            <div className="react-modal-body">
                <div className="row modal-input-group">
                    <label htmlFor="templateNameCreate" className="row">
                        <h5 className="col-md-2">Nome</h5>
                        <input 
                            type="text" maxLength={50} value={nameTemplate} 
                            onChange={(event) => setNameTemplate(event.target.value)}
                            id="templateNameCreate" className="modal-input col-md-10" 
                        />
                    </label>
                </div>
                
                <div className="row modal-input-group">
                    <label htmlFor="messageCreate" className="row">
                        <h5 className="col-md-2">Mensagem</h5>
                        <textarea 
                            maxLength={500} value={message} 
                            onChange={(event) => setMessage(event.target.value)}
                            id="messageCreate" className="modal-input col-md-10" 
                        />
                    </label>
                </div>

                <div className="row modal-input-group">
                    <label htmlFor="headerTemplateCreate" className="row">
                        <h5 className="col-md-2">Cabeçalho</h5>
                        <textarea 
                            maxLength={255} value={headerTemplate} 
                            onChange={(event) => setHeaderTemplate(event.target.value)}
                            id="headerTemplateCreate" className="modal-input col-md-10" 
                        />
                    </label>
                </div>

                <div className="row modal-input-group">
                    <label htmlFor="signatureTemplateCreate" className="row">
                        <h5 className="col-md-2">Assinatura</h5>
                        <textarea 
                            maxLength={255} value={signatureTemplate} 
                            onChange={(event) => setSignatureTemplate(event.target.value)}
                            id="signatureTemplateCreate" className="modal-input col-md-10" 
                        />
                    </label>
                </div>

                <div className="row modal-input-group">
                    <label htmlFor="templateCreate" className="row">
                        <h5 className="col-md-2">Imagem</h5>
                        <input 
                            type="file" id="templateCreate" className="modal-input col-md-7" 
                            accept=".png,.jpeg,.jpg" onChange={(event) => {
                                const fileImage = event.target.files[0];
                                setImageTemplate(fileImage);
                            }}
                        />
                        {imageTemplate !== undefined &&
                            <img src={URL.createObjectURL(imageTemplate)} alt="Imagem do Template de Histórico" width="200"></img>
                        }
                    </label>
                </div>
            </div>
            <div className="react-modal-foot">
                <div className="react-modal-btn">
                    <button className="btn btn-red" onClick={onRequestClose} >
                        Cancelar
                    </button>
                    <button 
                        className={btnBlocked ? "btn btn-blocked" : "btn btn-blue"} 
                        onClick={createTemplate} disabled={btnBlocked}
                        type="button"
                    >
                        Cadastrar
                    </button>
                </div>
            </div>
        </Modal>
    );
    
}

export default NewDeclarationTemplateModal;