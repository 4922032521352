import React from 'react';
import { api } from '../../../services/api';

import NewDeclarationTemplateModal from '../../DeclarationTemplateModal/NewDeclarationTemplateModal';
import DeleteDeclarationTemplateModal from '../../DeclarationTemplateModal/DeleteDeclarationTemplateModal';
import EditDeclarationTemplateModal from '../../DeclarationTemplateModal/EditDeclarationTemplateModal';
import Alert from '../../Alert';

import { FaTrash, FaPencilAlt } from 'react-icons/fa';

class DeclarationTemplates extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            templates: [],
            isNewDeclarationTemplateModalOpen: false,
            isDeleteDeclarationTemplateModalOpen: false,
            isEditDeclarationTemplateModalOpen: false,
            templateSelected: {},
            typeTemplateSelected: "all",
            typeAlert: '',
            messageAlert: '',
            visibleAlert: false
        };
        
        this.listTemplates = this.listTemplates.bind(this);
        
        this.closeAlert = this.closeAlert.bind(this);

        this.handleOpenNewDeclarationTemplateModal = this.handleOpenNewDeclarationTemplateModal.bind(this);
        this.handleCloseNewDeclarationTemplateModal = this.handleCloseNewDeclarationTemplateModal.bind(this);

        this.handleOpenDeleteDeclarationTemplateModal = this.handleOpenDeleteDeclarationTemplateModal.bind(this);
        this.handleCloseDeleteDeclarationTemplateModal = this.handleCloseDeleteDeclarationTemplateModal.bind(this);
    
        this.handleOpenEditDeclarationTemplateModal = this.handleOpenEditDeclarationTemplateModal.bind(this);
        this.handleCloseEditDeclarationTemplateModal = this.handleCloseEditDeclarationTemplateModal.bind(this);
    }

    componentDidMount() {
        this.listTemplates();
    }

    closeAlert(){
        this.setState({visibleAlert: false});
    }
    
    handleOpenNewDeclarationTemplateModal(){
        this.setState({isNewDeclarationTemplateModalOpen: true});
    }

    handleCloseNewDeclarationTemplateModal(operation = false, successOperation = false, messageAlert = undefined){
        this.setState({isNewDeclarationTemplateModalOpen: false});
        if(operation === true){
            if(successOperation === true){
                this.setState({
                    typeAlert: "success",
                    messageAlert: "Template cadastrado com sucesso!",
                    visibleAlert: true
                });
                this.listTemplates();
            }else{
                this.setState({
                    typeAlert: "error",
                    messageAlert: messageAlert !== undefined ? messageAlert : "Não foi possível cadastrar um novo Template. Tente novamente!",
                    visibleAlert: true
                });
            }
        }
    }

    handleOpenDeleteDeclarationTemplateModal(template){
        this.setState({
            templateSelected: template,
            isDeleteDeclarationTemplateModalOpen: true
        });
    }

    handleCloseDeleteDeclarationTemplateModal(operation = false, successOperation = false, messageAlert = undefined){
        this.setState({isDeleteDeclarationTemplateModalOpen: false});
        if(operation === true){
            if(successOperation === true){
                this.setState({
                    typeAlert: "success",
                    messageAlert: "Template excluído com sucesso!",
                    visibleAlert: true,
                    templates: this.state.templates.filter((item) => item.id_template_d !== this.state.templateSelected.id_template_d)
                });
            }else{
                this.setState({
                    typeAlert: "error",
                    messageAlert: messageAlert !== undefined ? messageAlert : "Não foi possível deletar o Template de Histórico. Tente novamente!",
                    visibleAlert: true
                });
            }
        }
    }

    handleOpenEditDeclarationTemplateModal(template){
        this.setState({
            templateSelected: template,
            isEditDeclarationTemplateModalOpen: true
        });
    }

    handleCloseEditDeclarationTemplateModal(operation = false, successOperation = false, messageAlert = undefined){
        this.setState({isEditDeclarationTemplateModalOpen: false});
        if(operation === true) {
            if(successOperation === true) {
                this.setState({
                    typeAlert: "success",
                    messageAlert: "Dados do Template atualizados com sucesso!",
                    visibleAlert: true
                });
                this.listTemplates();
            }else{
                this.setState({
                    typeAlert: "error",
                    messageAlert: messageAlert !== undefined ? messageAlert : "Não foi possível atualizar os dados do Template. Tente novamente!",
                    visibleAlert: true
                });
            }
        }
    }


    async listTemplates(){
        try {
            const response = await api.get('/templated');
            this.setState({templates: response.data.templates});   
        } catch (error) {
            this.setState({
                typeAlert: "error",
                messageAlert: "Não foi possível listar os Templates de Histórico",
                visibleAlert: true
            });
        }
    }
    
    render() {
        return (
            <div className="row m-0">
                <div className={this.state.visibleAlert === true ? "row m-0 area-alert" : "hide-area"}>    
                    <Alert type={this.state.typeAlert} message={this.state.messageAlert} closeAlert={this.closeAlert} />
                </div>

                <div className="navigation">
                    Configurações/ Templates de Declaração de Matrícula
                </div>             
                
                <div className="row action-panel">
                    <div className="col-md-8"></div>
                    <div className="btn-area col-md-4">
                        <button className="btn btn-primary d-block" onClick={this.handleOpenNewDeclarationTemplateModal}>
                            + Novo Template
                        </button>
                    </div>
                </div>

                <div className="row m-0">
                    <div className="table">
                        <table>
                            <thead>
                                <tr className='titles'>
                                    <th>Nome</th>
                                    <th>Mensagem</th>
                                    <th>Imagem</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.templates.map((template) => (
                                    <tr className='rows' key={"template-d-id-"+template.id_template_d}>
                                        <td>{template.name_template}</td>
                                        <td>{template.message_template}</td>
                                        <td>    
                                            {template.image_template !== undefined && template.image_template !== null &&
                                                <img src={process.env.REACT_APP_BACKEND_URL + '/' + template.image_template} alt="Imagem de Capa do Template de Histórico" width="200"></img>
                                            }
                                        </td>
                                        <td className="settings">
                                            <div className="settings-icons">
                                                <span className="icon edit" title="Editar Programa" onClick={() => this.handleOpenEditDeclarationTemplateModal(template)}>
                                                    <FaPencilAlt />
                                                </span>
                                                <span className="icon delete" title="Excluir Programa" onClick={() => this.handleOpenDeleteDeclarationTemplateModal(template)}>
                                                    <FaTrash />
                                                </span>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>     
                    </div>
                </div>

                {this.state.isNewDeclarationTemplateModalOpen &&
                    <NewDeclarationTemplateModal
                        id="newDeclarationTemplateModal"
                        isOpen={this.state.isNewDeclarationTemplateModalOpen} 
                        onRequestClose={this.handleCloseNewDeclarationTemplateModal}
                    />
                }

                {this.state.isDeleteDeclarationTemplateModalOpen &&
                    <DeleteDeclarationTemplateModal
                        id="deleteDeclarationTemplateModal"
                        isOpen={this.state.isDeleteDeclarationTemplateModalOpen}
                        onRequestClose={this.handleCloseDeleteDeclarationTemplateModal}
                        template={this.state.templateSelected}
                    />
                }

                {this.state.isEditDeclarationTemplateModalOpen &&
                    <EditDeclarationTemplateModal
                        id="editDeclarationTemplateModal"
                        isOpen={this.state.isEditDeclarationTemplateModalOpen}
                        onRequestClose={this.handleCloseEditDeclarationTemplateModal}
                        template={this.state.templateSelected}
                    />    
                }

            </div>
        );
    }
}

export default DeclarationTemplates;