import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { api } from '../../../services/api';

import { AiOutlineClose } from "react-icons/ai";

function SelectTemplateModal ({isOpen, onRequestClose}){
    const [templates, setTemplates] = useState([]);
    const [templateName, setTemplateName] = useState('');
    const [searchTemplateName, setSearchTemplateName] = useState('');

    async function listTemplates(){
        try {
            const response = await api.get('/templatec');
            setTemplates(response.data.templates);    
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        if (templates.length === 0){
            listTemplates();
            console.log(templates);
        }
    });
    
    return (
        <view>
           

            <Modal
                ariaHideApp={false}
                overlayClassName="react-modal-overlay"
                className="react-modal-content"
                isOpen={isOpen} 
                onRequestClose={onRequestClose}
            >
                <button 
                    className="react-modal-close"
                    onClick={onRequestClose} 
                >
                    <AiOutlineClose />
                </button>

                <div className="react-modal-title">
                    <h4 id="modal-title">Selecionar Template de Certificado</h4>
                </div>
                
                <div className="react-modal-body" style={{maxHeight: '85vh'}}>

                    <div className="row m-0 action-panel">
                        <div className='col-md-4'>
                            <input 
                                type="text" maxLength={50} placeholder="Nome do Template" value={templateName}
                                onChange={(event) => setTemplateName(event.target.value)}
                                id="nameTemplateSearch" className="modal-input search-input" 
                            />
                        </div>
                        <div className="col-md-4"></div>
                        <div className="btn-area col-md-2">
                            <button className="btn btn-primary d-block" onClick={() => {
                                setSearchTemplateName('');
                                setTemplateName('');
                            }}>
                                Limpar
                            </button>
                        </div>
                        <div className="btn-area col-md-2">
                            <button className="btn btn-primary d-block" onClick={() => {
                                setSearchTemplateName(templateName);
                            }}>
                                Buscar
                            </button>
                        </div>
                    </div>
                    
                    <div className="row m-0 modal-input-group">
                        <div className="table">
                            <table>
                                <thead>
                                    <tr className='titles'>
                                        <th>Nome</th>
                                        <th>Capa</th>
                                        <th>Cores</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {console.log(templates)}
                                    {templates.map((template) => (
                                        (searchTemplateName === '' || template.name_template.indexOf(searchTemplateName) !== -1) && 
                                        <tr className='rows' key={template.id_template_c}>
                                            <td>{template.name_template}</td>
                                            <td>    
                                                {template.cover_template !== undefined && template.cover_template !== null &&
                                                    <img src={process.env.REACT_APP_BACKEND_URL + '/' + template.cover_template} alt="Imagem de Capa do Template de Certificado" width="200"></img>
                                                }
                                            </td>
                                            <td>
                                                <div className="colors-template">
                                                    <input 
                                                        type="color" key={"t"+template.id_template_c+"c0"} disabled
                                                        value={template.colors_template.split(';')[0]} className="row"
                                                    /> 
                                                    <input 
                                                        type="color" key={"t"+template.id_template_c+"c1"} disabled
                                                        value={template.colors_template.split(';')[1]} className="row"
                                                    /> 
                                                    <input 
                                                        type="color" key={"t"+template.id_template_c+"c2"} disabled
                                                        value={template.colors_template.split(';')[2]} className="row"
                                                    /> 
                                                    <input 
                                                        type="color" key={"t"+template.id_template_c+"c3"} disabled
                                                        value={template.colors_template.split(';')[3]} className="row"
                                                    /> 
                                                </div>
                                            </td>
                                            <td className="settings">
                                                <button className="btn btn-blue" onClick={() => {onRequestClose(true, true, template)}  }>
                                                    Selecionar
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>     
                        </div>
                    </div>
                </div>
            </Modal>
        </view>
    );
    
}

export default SelectTemplateModal;