import React from 'react';
import { api } from '../../../services/api';
import Alert from '../../Alert';
import FileSaver from 'file-saver';

import { FaAssistiveListeningSystems, FaDownload } from 'react-icons/fa';
import { IMaskInput } from "react-imask";

import Loader from '../../Loader';

class Declaration extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            courses: [],
            avas: [],
            selectedAva: undefined,
            selectedCPF: undefined,
            loadingDocument: false,
            typeAlert: "",
            messageAlert: "",
            typeSearch: "cpf",
            nameStudent: "",
            visibleAlert: FaAssistiveListeningSystems,
        };

        this.searchStudent = this.searchStudent.bind(this);
        this.listCourses = this.listCourses.bind(this);
        this.listAvas = this.listAvas.bind(this);
        this.format_date = this.format_date.bind(this);
        this.closeAlert = this.closeAlert.bind(this);
        this.generateDeclaration = this.generateDeclaration.bind(this);
    }

    componentDidMount() {
        this.listAvas();
    }

    format_date = (date) => {
        if (date === null) {
            return 'Sem data definida';
        } 

        const w_date = new Date(date);
        const u_date = new Date( w_date.getTime() - w_date.getTimezoneOffset() * -60000 )
        const day = u_date.getDate().toString().padStart(2, "0");
        const month = (u_date.getMonth() + 1).toString().padStart(2, "0");
        const year = u_date.getFullYear();
        const f_date = day + '/' + month + '/' + year;
    
        return f_date;
    }

    closeAlert(){
        this.setState({visibleAlert: false});
    }

    async searchStudent(){
        this.setState({ loadingDocument: true, visibleAlert: false });
        let dados = {};
        let rota = "";
        if (this.state.typeSearch === "name"){
            rota = "courses_by_ava_and_name";
            dados = { name_student: this.state.nameStudent, id_ava: this.state.selectedAva};
            this.setState({ selectedCPF: undefined });
        } else {
            rota = "courses_by_ava_and_cpf";
            dados = { cpf_student: this.state.selectedCPF, id_ava: this.state.selectedAva};
            this.setState({ nameStudent: "" });
        }
        await this.listCourses(rota, dados);
        this.setState({ loadingDocument: false });
    }

    async listCourses(rota, dados) {
        try {
            const response = await api.post(`/student/${rota}`, dados);
            this.setState({courses: response.data ? response.data.filter(course => course.end_date === null) : []});
        } catch (e) {
            this.setState({
                typeAlert: "error",
                messageAlert: "Não foi possível encontrar cursos cadastrados para esse estudante.",
                visibleAlert: true
            });
        }
    }

    async listAvas(){
        try {
            const response = await api.get('/ava/');
            this.setState({ 
                avas: response.data.avas,
                selectedAva: response.data.avas.length > 0 ? response.data.avas[0].id_ava : undefined
            });
        } catch (error) {
            this.setState({
                typeAlert: "error",
                messageAlert: "Não foi possível listar os os AVAs",
                visibleAlert: true
            });
        }
    }

    async generateDeclaration(id_ava, code_ava_course, code_ava_student){
        this.setState({ loadingDocument: true, visibleAlert: false });
        try {
            const response = await api.post('/declaration', {
                id_ava: id_ava,
                code_ava_course: code_ava_course,
                code_ava_student: code_ava_student
            }, { responseType: 'blob' });
            
            const blob = new Blob([response.data], { type: 'application/pdf' });
            const filename = "declaracao.pdf";
            FileSaver.saveAs(blob, filename);

        } catch (error) {
            let msg_error = undefined;
            if (error && error.response && error.response.data) {
                const errorData = error.response.data;
                if (typeof errorData.error === 'string') {
                msg_error = errorData.error;
                }
            }
            this.setState({
                typeAlert: "error",
                messageAlert: msg_error !== undefined ? msg_error : "Não foi possível emitir a declaração de matrícula!",
                visibleAlert: true
            });
        }
        this.setState({ loadingDocument: false });
    }

    render() {
        return (
            <div className="row m-0">
                <div className={this.state.visibleAlert === true ? "row m-0 area-alert" : "hide-area"}>    
                    <Alert type={this.state.typeAlert} message={this.state.messageAlert} closeAlert={this.closeAlert} />
                </div>

                <div className="navigation">
                    Documentos / Emissão de Declaração
                </div>

                <div className="row action-panel">
                    <div className="col-md-3 select">
                        <select className="form-select"
                            onChange={(event) => this.setState({selectedAva: event.target.value})}>
                            {this.state.avas.map((ava) => (
                                <option 
                                    className="select-options" 
                                    key={"ava"+ava.id_ava} 
                                    value={ava.id_ava}
                                >
                                    {ava.name_ava}
                                </option>
                            ))} 
                        </select>
                    </div>
                    <div className="col-md-4 input">
                        <IMaskInput 
                            type="text" maxLength={14} placeholder="CPF do Aluno"
                            onAccept={(value) => this.setState({ selectedCPF: value.replaceAll('.', '').replaceAll('-', '') })}
                            id="studentCpf" className= {(this.state.typeSearch === "cpf") ? "input search-input col-md-12" : "hide-area"}
                            mask="000.000.000-00"
                        />
                        <input 
                            type="text" maxLength={50} value={this.state.nameStudent} placeholder="Nome do aluno"
                            onChange={(event) => this.setState({ nameStudent: event.target.value })}
                            id="studentName" className= {(this.state.typeSearch === "name") ? "input search-input col-md-12" : "hide-area"}
                        />
                    </div>
                    <div className="col-md-3 select">
                        <select className="form-select" value={this.state.typeSearch}
                            onChange={(event) => this.setState({typeSearch: event.target.value})}>
                            <option className="select-options"  key="cpf-option" value="cpf">Buscar por CPF</option>
                            <option className="select-options"  key="name-option" value="name">Busca     por Nome</option>
                        </select>
                    </div>
                    <div className="btn-area col-md-2">
                        <button className="btn btn-primary d-block" onClick={() => { this.searchStudent() }}>
                            Buscar
                        </button>
                    </div>
                </div>

                <div className="row m-0">
                    <div className="table">
                        <table>
                            <thead>
                                <tr className='titles'>
                                    <th>Estudante</th>
                                    <th>E-mail</th>
                                    <th>Curso</th>
                                    <th>AVA</th>
                                    <th>Data de início</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.courses.map((course) => (
                                    <tr className='rows' key={course.id_student_modality}>
                                        <td>{course.Student.name_student}</td>
                                        <td>{course.Student.email_student}</td>
                                        <td>{course.Modality.name_modality}</td>
                                        <td>{course.Modality.Course.Program.Ava.name_ava}</td>
                                        <td>{this.format_date(course.start_date)}</td>
                                        <td className="settings">
                                            <div className="settings-icons">
                                                <span className="icon" title="Emitir Declaração de Matrícula" 
                                                    onClick={() => this.generateDeclaration(course.Modality.Course.Program.id_ava, course.Modality.Course.code_ava_course, course.code_ava_student)}
                                                >
                                                    <FaDownload/>
                                                </span>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        {this.state.courses.length === 0 &&
                            <div>
                                <p className='text-center m-2'>Aluno não está matriculado em nenhum curso em andamento.</p>
                            </div>
                        }
                    </div>
                </div>
                {this.state.loadingDocument ? <Loader /> : null}
            </div>
        );
    }
}

export default Declaration;
