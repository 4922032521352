import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { api } from '../../../services/api';

import { AiOutlineClose } from "react-icons/ai";
import NewTemplateModal from '../../TemplateModal/NewTemplateModal';
import SelectTemplateModal from '../../TemplateModal/SelectTemplateModal';
import NewDeclarationTemplateModal from '../../DeclarationTemplateModal/NewDeclarationTemplateModal';
import SelectDeclarationTemplateModal from '../../DeclarationTemplateModal/SelectDeclarationTemplateModal';
import NewSRTemplateModal from '../../SRTemplateModal/NewSRTemplateModal';
import SelectSRTemplateModal from '../../SRTemplateModal/SelectSRTemplateModal';


function NewCourseModal ({isOpen, onRequestClose, avas}){
    const [btnBlocked, setBtnBlocked] = useState(true);

    const [nameCourse, setNameCourse] = useState('');
    const [codeCourse, setCodeCourse] = useState(0);
    const [avaCourses, setAvaCourses] = useState([]);
    const [chCourse, setChCourse] = useState(0);
    const [contentCourse, setContentCourse] = useState('');
    const [idAva, setIdAva] = useState(0);
    const [idProgram, setIdProgram] = useState(0);
    const [programs, setPrograms] = useState([]);
    const [startOffer, setStartOffer] = useState('');
    const [endOffer, setEndOffer] = useState('');
    const [templateChosen, setTemplateChosen] = useState({});
    const [declarationTemplateChosen, setDeclarationTemplateChosen] = useState({});
    const [schoolRecordTemplateChosen, setSchoolRecordTemplateChosen] = useState({});

    const [isNewTemplateModalOpen, setIsNewTemplateModalOpen] = useState(false);
    const [isSelectTemplateModalOpen, setIsSelectTemplateModalOpen] = useState(false);

    const [isNewDeclarationTemplateModalOpen, setIsNewDeclarationTemplateModalOpen] = useState(false);
    const [isSelectDeclarationTemplateModalOpen, setIsSelectDeclarationTemplateModalOpen] = useState(false);

    const [isNewSchoolRecordTemplateModalOpen, setIsNewSchoolRecordTemplateModalOpen] = useState(false);
    const [isSelectSchoolRecordTemplateModalOpen, setIsSelectSchoolRecordTemplateModalOpen] = useState(false);

    useEffect(() => {
        if(nameCourse.length === 0 || idProgram === 0 || codeCourse <= 0 || chCourse <= 0 || startOffer === "" || endOffer === ""){    
            setBtnBlocked(true);
        }else {
            setBtnBlocked(false);
        }
    }, [nameCourse, codeCourse, chCourse, idProgram, startOffer, endOffer]);

    useEffect(() => {
        async function listPrograms(){
            const response = await api.get(`/program/list/${idAva}`);
            setPrograms(response.data.programs);
        }

        setPrograms([]);
        listPrograms();
    }, [idAva]);

    useEffect(() => {
        async function searchCourses(){
            const response = await api.post('external_data/courses', {
                id_ava: parseInt(idAva),
                id_program: parseInt(idProgram)
            });
            setAvaCourses(response.data.courses);
        }

        setCodeCourse(0);
        setAvaCourses([]);
        searchCourses();
    }, [idAva, idProgram]);
    
    async function createCourse(){
        try {
            await api.post('/course/', {
                id_program: parseInt(idProgram),
                name_course: nameCourse,
                code_ava_course: codeCourse,
                ch_course: chCourse,
                content_course: contentCourse,
                id_template_c: templateChosen.id_template_c,
                id_template_d: declarationTemplateChosen.id_template_d,
                id_template_h: schoolRecordTemplateChosen.id_template_h,
                start_offer_period: startOffer,
                end_offer_period: endOffer
            });
            onRequestClose(true, true);
        } catch (error) {
            let msg_error = undefined;
            
            if (error && error.response && error.response.data) {
                const errorData = error.response.data;
                if (typeof errorData.error === 'string') {
                  msg_error = errorData.error;
                }
            }

            onRequestClose(true, false, msg_error);
        }
    }

    function handleOpenNewTemplateModal(){
        setIsNewTemplateModalOpen(true);
    }

    function handleCloseNewTemplateModal(operation = false, successOperation = undefined, templateChosen = undefined){
        setIsNewTemplateModalOpen(false);
        if(operation === true){
            setTemplateChosen(templateChosen);
        }
    }

    function handleOpenSelectTemplateModal(){
        setIsSelectTemplateModalOpen(true);
    }

    function handleCloseSelectTemplateModal(operation = false, successOperation = undefined, templateChosen = undefined){
        setIsSelectTemplateModalOpen(false);
        if(operation === true){
            setTemplateChosen(templateChosen);
        }
    }

    function handleOpenNewDeclarationTemplateModal(){
        setIsNewDeclarationTemplateModalOpen(true);
    }

    function handleCloseNewDeclarationTemplateModal(operation = false, successOperation = undefined, declarationtemplateChosen = undefined){
        setIsNewDeclarationTemplateModalOpen(false);
        if(operation === true){
            setDeclarationTemplateChosen(declarationtemplateChosen);
        }
    }

    function handleOpenSelectDeclarationTemplateModal(){
        setIsSelectDeclarationTemplateModalOpen(true);
    }

    function handleCloseSelectDeclarationTemplateModal(operation = false, successOperation = undefined, declarationtemplateChosen = undefined){
        setIsSelectDeclarationTemplateModalOpen(false);
        if(operation === true){
            setDeclarationTemplateChosen(declarationtemplateChosen);
        }
    }

    function handleOpenNewSchoolRecordTemplateModal(){
        setIsNewSchoolRecordTemplateModalOpen(true);
    }

    function handleCloseNewSchoolRecordTemplateModal(operation = false, successOperation = undefined, schoolRecordtemplateChosen = undefined){
        setIsNewSchoolRecordTemplateModalOpen(false);
        if(operation === true){
            setSchoolRecordTemplateChosen(schoolRecordtemplateChosen);
        }
    }

    function handleOpenSelectSchoolRecordTemplateModal(){
        setIsSelectSchoolRecordTemplateModalOpen(true);
    }

    function handleCloseSelectSchoolRecordTemplateModal(operation = false, successOperation = undefined, schoolRecordtemplateChosen = undefined){
        setIsSelectSchoolRecordTemplateModalOpen(false);
        if(operation === true){
            setSchoolRecordTemplateChosen(schoolRecordtemplateChosen);
        }
    }

    return (
        <view>
            <Modal
                ariaHideApp={false}
                overlayClassName="react-modal-overlay"
                className="react-modal-content"
                isOpen={isOpen} 
                onRequestClose={onRequestClose}
            >
                <button 
                    className="react-modal-close"
                    onClick={onRequestClose} 
                >
                    <AiOutlineClose />
                </button>
                <div className="react-modal-title">
                    <h4 id="modal-title">Cadastro de Novo Curso</h4>
                </div>

                <div className="react-modal-body">
                    <div className="row modal-input-group">
                        <label htmlFor="courseNameCreate" className="row">
                            <h5 className="col-md-2">Nome</h5>
                            <input 
                                type="text" maxLength={200} value={nameCourse} 
                                onChange={(event) => setNameCourse(event.target.value)}
                                id="courseNameCreate" className="modal-input col-md-10" 
                            />
                        </label>
                    </div>
                    <div className="row modal-input-group">
                        <label htmlFor="courseIdAvaCreate" className="row">
                            <h5 className="col-md-2">AVA</h5>
                            <select id="courseIdAvaCreate" className="modal-input col-md-10" onChange={(event) => setIdAva(parseInt(event.target.value))}>
                                <option value={0}>Selecione um AVA</option>
                                {avas.map((ava) => (
                                    <option key={"ava"+ava.id_ava} value={ava.id_ava}>{ava.name_ava}</option>
                                ))} 
                            </select>
                        </label>
                    </div>
                    <div className="row modal-input-group">
                        <label htmlFor="courseIdProgramCreate" className="row">
                            <h5 className="col-md-2">Programa</h5>
                            <select id="courseIdProgramCreate" className="modal-input col-md-10" onChange={(event) => setIdProgram(parseInt(event.target.value))}>
                                <option value={0}>Selecione um Programa</option>
                                {programs.map((program) => (
                                    <option key={"program"+program.id_program} value={program.id_program}>{program.Modality.name_modality}</option>
                                ))} 
                            </select>
                        </label>
                    </div>
                    <div className="row modal-input-group">
                        <label htmlFor="courseCodeCreate" className="row">
                            <h5 className="col-md-2">Curso no AVA</h5>
                            <select id="courseCodeCreate" className="modal-input col-md-10" 
                                onChange={(event) => setCodeCourse(parseInt(event.target.value))}
                            >
                                <option value={0}>Selecione um Curso Equivalente</option>
                                {avaCourses.map((avaCourse) => (
                                    <option key={"course"+avaCourse.code_ava_course} value={avaCourse.code_ava_course}>
                                        {avaCourse.name_course}
                                    </option>
                                ))} 
                            </select>
                        </label>
                    </div>
                    <div className="row modal-input-group">
                        <label htmlFor="courseChCeate" className="row">
                            <h5 className="col-md-2">Carga Horária</h5>
                            <input 
                                type="number" value={chCourse} 
                                onChange={(event) => setChCourse(event.target.value)} 
                                id="courseChCeate" className="modal-input col-md-10" 
                            />
                        </label>
                    </div>
                    <div className="row modal-input-group">
                        <label htmlFor="courseContentCreate" className="row">
                            <h5 className="col-md-2">Conteúdo</h5>
                            <textarea 
                                maxLength={1170} value={contentCourse} 
                                onChange={(event) => setContentCourse(event.target.value)}
                                id="courseContentCreate" className="modal-input col-md-10" 
                            />
                        </label>
                    </div>
                    <div className="row modal-input-group">
                        <label htmlFor="courseStartOffer" className="row">
                            <h5 className="col-md-2">Início da oferta</h5>
                            <input 
                                type="date" value={startOffer} 
                                onChange={(event) => setStartOffer(event.target.value)} 
                                id="courseStartOffer" className="modal-input col-md-10" 
                            />
                        </label>
                    </div>
                    <div className="row modal-input-group">
                        <label htmlFor="courseEndOffer" className="row">
                            <h5 className="col-md-2">Fim da oferta</h5>
                            <input 
                                type="date" value={endOffer} 
                                onChange={(event) => setEndOffer(event.target.value)} 
                                id="courseEndOffer" className="modal-input col-md-10" 
                            />
                        </label>
                    </div> 
                    <div className="row modal-input-group">
                        <label className="row">
                            <h5 className="col-md-2">Certificado</h5>
                                <button className="btn btn-green" onClick={handleOpenNewTemplateModal}>
                                    Novo Template
                                </button>
                                <button className="btn btn-blue" onClick={handleOpenSelectTemplateModal}>
                                    Buscar Template
                                </button>
                                {templateChosen.cover_template !== undefined && templateChosen.cover_template !== null &&
                                    <img src={process.env.REACT_APP_BACKEND_URL + '/' + templateChosen.cover_template} alt="Imagem de Capa do Template de Certificado" width="100px"></img>
                                }
                                {templateChosen.verse_template !== undefined && templateChosen.verse_template !== null &&
                                    <img src={process.env.REACT_APP_BACKEND_URL + '/' + templateChosen.verse_template} alt="Imagem de Verso do Template de Certificado" width="100px"></img>
                                }
                        </label>
                    </div>

                    <div className="row modal-input-group">
                        <label className="row">
                            <h5 className="col-md-2">Declaração</h5>
                                <button className="btn btn-green" onClick={handleOpenNewDeclarationTemplateModal}>
                                    Novo Template
                                </button>
                                <button className="btn btn-blue" onClick={handleOpenSelectDeclarationTemplateModal}>
                                    Buscar Template
                                </button>
                                {declarationTemplateChosen.image_template !== undefined && declarationTemplateChosen.image_template !== null &&
                                    <img src={process.env.REACT_APP_BACKEND_URL + '/' + declarationTemplateChosen.image_template} alt="Imagem do Template de Declaração" width="100px"></img>
                                }
                        </label>
                    </div>

                    <div className="row modal-input-group">
                        <label className="row">
                            <h5 className="col-md-2">Histórico</h5>
                                <button className="btn btn-green" onClick={handleOpenNewSchoolRecordTemplateModal}>
                                    Novo Template
                                </button>
                                <button className="btn btn-blue" onClick={handleOpenSelectSchoolRecordTemplateModal}>
                                    Buscar Template
                                </button>
                                {schoolRecordTemplateChosen.image_template !== undefined && schoolRecordTemplateChosen.image_template !== null &&
                                    <img src={process.env.REACT_APP_BACKEND_URL + '/' + schoolRecordTemplateChosen.image_template} alt="Imagem do Template de Histórico" width="100px"></img>
                                }
                        </label>
                    </div>
                </div>
                <div className="react-modal-foot">            
                    <div className="react-modal-btn">
                        <button className="btn btn-red" onClick={onRequestClose} >
                            Cancelar
                        </button>
                        <button 
                            className={btnBlocked ? "btn btn-blocked" : "btn btn-blue"} 
                            onClick={createCourse} disabled={btnBlocked}
                            type="button"
                        >
                            Cadastrar
                        </button>
                    </div>
                </div>
            </Modal>

            {isNewTemplateModalOpen &&
                <NewTemplateModal
                    id="newTemplateModal"
                    isOpen={isNewTemplateModalOpen} 
                    onRequestClose={handleCloseNewTemplateModal}
                />
            }

            {isSelectTemplateModalOpen &&
                <SelectTemplateModal
                    id="selectTemplateModal"
                    isOpen={isSelectTemplateModalOpen}
                    onRequestClose={handleCloseSelectTemplateModal}
                />
            }

            {isNewDeclarationTemplateModalOpen &&
                <NewDeclarationTemplateModal
                    id="newDeclarationTemplateModal"
                    isOpen={isNewDeclarationTemplateModalOpen} 
                    onRequestClose={handleCloseNewDeclarationTemplateModal}
                />
            }

            {isSelectDeclarationTemplateModalOpen &&
                <SelectDeclarationTemplateModal
                    id="selectDeclarationTemplateModal"
                    isOpen={isSelectDeclarationTemplateModalOpen}
                    onRequestClose={handleCloseSelectDeclarationTemplateModal}
                />
            }

            {isNewSchoolRecordTemplateModalOpen &&
                <NewSRTemplateModal
                    id="newSchoolRecordTemplateModal"
                    isOpen={isNewSchoolRecordTemplateModalOpen} 
                    onRequestClose={handleCloseNewSchoolRecordTemplateModal}
                />
            }

            {isSelectSchoolRecordTemplateModalOpen &&
                <SelectSRTemplateModal
                    id="selectSchoolRecordTemplateModal"
                    isOpen={isSelectSchoolRecordTemplateModalOpen}
                    onRequestClose={handleCloseSelectSchoolRecordTemplateModal}
                />
            }
        </view>
    );
    
}

export default NewCourseModal;