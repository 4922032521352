import React from "react";
import { api } from "../../../services/api";
import { IMaskInput } from "react-imask";
import Alert from "../../Alert";
import Loader from "../../Loader";

import { FaSyncAlt } from 'react-icons/fa';

const Students = () => {
  const [avas, setAvas] = React.useState([]);
  const [students, setStudents] = React.useState([]);
  const [selectedCPF, setSelectedCPF] = React.useState("");
  const [selectedAVA, setSelectedAVA] = React.useState(undefined);
  const [isLoading, setIsLoading] = React.useState(false);

  const [alert, setAlert] = React.useState({
    typeAlert: "",
    messageAlert: "",
  });
  const [alertIsVisible, setAlertIsVisible] = React.useState(false);

  async function listAvas() {
    try {
      const response = await api.get("/ava");

      setAvas(response.data.avas);
      setSelectedAVA(response.data.avas.length > 0 ? response.data.avas[0].id_ava : undefined);
    } catch (error) {
      setAlert({
        typeAlert: "error",
        messageAlert: "Não foi possível carregar a lista de Avas",
      });

      setAlertIsVisible(true);
    }
  }

  async function updateStudent(student) {
    setIsLoading(true);
    setAlertIsVisible(false);

    try {
      const response = await api.put(
        `import/updateStudent/${student.id_ava}/${student.code_ava_student}`
      );

      setAlert({
        typeAlert:
          response.data.msg === "Atualizado com sucesso!"
            ? "success"
            : "error",
        messageAlert: response.data.msg,
      });

      setAlertIsVisible(true);
    } catch (e) {
      setAlert({
        typeAlert: "error",
        messageAlert: "Não foi possível atualizar os dados do estudante.",
      });

      setAlertIsVisible(true);
    }

    setIsLoading(false);
  }

  async function searchStudent() {
    setIsLoading(true);
    setAlertIsVisible(false);

    try {
      if (selectedAVA !== undefined ){
        const response = await api.post("student/search_by_ava_and_cpf", {
          cpf_student: selectedCPF,
          id_ava: selectedAVA,
        });
        setStudents(response.data.students);
      }
    } catch (e) {
      setAlert({
        typeAlert: "error",
        messageAlert: "Não foi possível buscar os dados do estudante.",
      });

      setAlertIsVisible(true);
    }

    setIsLoading(false);
  }

  React.useEffect(() => {
    listAvas();
  }, []);

  return (
    <div className="row m-0">
      <div
        className={
          alertIsVisible === true ? "row m-0 area-alert" : "hide-area"
        }
      >
        <Alert
          type={alert.typeAlert}
          message={alert.messageAlert}
          closeAlert={() => setAlertIsVisible(false)}
        />
      </div>

      <div className="navigation">Configurações / Alunos</div>

      <div className="row justify-content-between action-panel">
        <div className="col-md-4 ">
          <select
            className="form-select"
            onChange={(event) => setSelectedAVA(parseInt(event.target.value))}
          >
            {avas.map((ava) => (
              <option
                className="select-options"
                key={"ava" + ava.id_ava}
                value={ava.id_ava}
              >
                {ava.name_ava}
              </option>
            ))}
          </select>
        </div>

        <div className="col-md-4 input">
          <IMaskInput
            type="text"
            maxLength={14}
            id="studentCpf"
            mask="000.000.000-00"
            placeholder="CPF do Aluno"
            className="input search-input col-md-12 border-none"
            onAccept={(value) =>
              setSelectedCPF(value.replaceAll(".", "").replaceAll("-", ""))
            }
          />
        </div>

        <div className="col-md-4 btn-area">
          <button className="btn btn-primary d-block" onClick={() => searchStudent()}>
            Buscar
          </button>
        </div>
      </div>

      <div className="row m-0">
        <div className="table">
          <table>
            <thead>
              <tr className='titles'>
                <th>Estudante</th>
                <th>CPF</th>
                <th>E-mail</th>
                <th>AVA</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {students.map((student) => (  
                <tr className='rows' key={"student-"+student.id_ava+"-"+student.code_ava_student}>
                  <td>{student.name_student}</td>
                  <td>{student.cpf_student.replace(/\D/g, '').padStart(11, '0').replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')}</td>
                  <td>{student.email_student}</td>
                  <td>{student.id_ava}</td>
                  <td className="settings">
                    <div className="settings-icons">
                      <span className="icon" title="Atualizar Dados do Aluno" onClick={() => updateStudent(student)}>
                        <FaSyncAlt />
                      </span>
                    </div>
                  </td>
                </tr>
              ))} 
            </tbody>
          </table>
        </div>
      </div>

      {isLoading ? <Loader /> : null}
    </div>
  );
};

export default Students;
