import React from 'react';
import { api } from '../../../services/api';

import NewUserModal from '../../UserModal/NewUserModal';
import ResetUserModal from '../../UserModal/ResetUserModal';
import EditUserModal from '../../UserModal/EditUserModal';
import DeleteUserModal from '../../UserModal/DeleteUserModal';
import Alert from '../../Alert';

import { FaTrash, FaPencilAlt } from 'react-icons/fa';
import { GrSettingsOption } from 'react-icons/gr';

class Users extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            users: [],
            isNewUserModalOpen: false,
            isDeleteUserModalOpen: false,
            isEditUserModalOpen: false,
            isResetUserModalOpen: false,
            userSelected: {},
            typeAlert: '',
            messageAlert: '',
            visibleAlert: false
        };
        
        this.listUsers = this.listUsers.bind(this);
        this.closeAlert = this.closeAlert.bind(this);
        
        this.handleOpenNewUserModal = this.handleOpenNewUserModal.bind(this);
        this.handleCloseNewUserModal = this.handleCloseNewUserModal.bind(this);

        this.handleOpenDeleteUserModal = this.handleOpenDeleteUserModal.bind(this);
        this.handleCloseDeleteUserModal = this.handleCloseDeleteUserModal.bind(this);

        this.handleOpenEditUserModal = this.handleOpenEditUserModal.bind(this);
        this.handleCloseEditUserModal = this.handleCloseEditUserModal.bind(this);

        this.handleOpenResetUserModal = this.handleOpenResetUserModal.bind(this);
        this.handleCloseResetUserModal = this.handleCloseResetUserModal.bind(this);
    }

    componentDidMount() {
        this.listUsers();            
    }

    closeAlert(){
        this.setState({visibleAlert: false});
    }
    
    handleOpenNewUserModal(){
        this.setState({isNewUserModalOpen: true});
    }

    handleCloseNewUserModal(operation = false, successOperation = false, messageAlert = undefined){
        this.setState({isNewUserModalOpen: false});
        if(operation === true){
            if(successOperation === true){
                this.setState({
                    typeAlert: "success",
                    messageAlert: "Usuário cadastrado com sucesso!",
                    visibleAlert: true
                });
                this.listUsers();
            }else{
                this.setState({
                    typeAlert: "error",
                    messageAlert: messageAlert !== undefined ? messageAlert : "Não foi possível cadastrar um novo usuário. Tente novamente!",
                    visibleAlert: true
                });
            }
        }
    }

    handleOpenDeleteUserModal(user){
        this.setState({
            userSelected: user, 
            isDeleteUserModalOpen: true
        });
    }

    handleCloseDeleteUserModal(operation = false, successOperation = false, messageAlert = undefined){
        this.setState({isDeleteUserModalOpen: false});
        if(operation === true){
            if(successOperation === true){
                this.setState({
                    typeAlert: "success",
                    messageAlert: "Usuário excluído com sucesso!",
                    visibleAlert: true,
                    users: this.state.users.filter((item) => item.id_user !== this.state.userSelected.id_user)
                });
            }else{
                this.setState({
                    typeAlert: "error",
                    messageAlert: messageAlert !== undefined ? messageAlert : "Não foi possível deletar o usuário. Tente novamente!",
                    visibleAlert: true
                });
            }
        }
    }

    handleOpenEditUserModal(user){
        this.setState({
            userSelected: user,
            isEditUserModalOpen: true
        });
    }

    handleCloseEditUserModal(operation = false, successOperation = false, messageAlert = undefined){
        this.setState({isEditUserModalOpen: false});
        if(operation === true){
            if(successOperation === true){
                this.setState({
                    typeAlert: "success",
                    messageAlert: "Dados do usuário atualizados com sucesso!",
                    visibleAlert: true
                });
                this.listUsers();
            }else{
                this.setState({
                    typeAlert: "error",
                    messageAlert: messageAlert !== undefined ? messageAlert : "Não foi possível atualizar os dados do usuário. Tente novamente!",
                    visibleAlert: true
                });
            }
        }
    }

    handleOpenResetUserModal(user){
        this.setState({
            userSelected: user, 
            isResetUserModalOpen: true
        });
    }

    handleCloseResetUserModal(operation = false, successOperation = false, messageAlert = undefined){
        this.setState({isResetUserModalOpen: false});
        if(operation === true){
            if(successOperation){
                this.setState({
                    typeAlert: "success",
                    messageAlert: "Senha do usuário resetada com sucesso!",
                    visibleAlert: true
                });
            }else{
                this.setState({
                    typeAlert: "error",
                    messageAlert: messageAlert !== undefined ? messageAlert : "Não foi possível resetar a senha do usuário. Tente novamente!",
                    visibleAlert: true
                });
            }
        }
    }

    async listUsers(){
        const response = await api.get('/user');
        this.setState({users: response.data.users});
    }

    render() {
        return (
            <div className="row m-0">
                <div className={this.state.visibleAlert === true ? "row m-0 area-alert" : "hide-area"}>    
                    <Alert type={this.state.typeAlert} message={this.state.messageAlert} closeAlert={this.closeAlert} />
                </div>

                <div className="navigation">
                    Sistema / Gerenciamento de Usuários
                </div>

                <div className="row action-panel">
                    <div className="col-md-9"></div>
                    <div className="btn-area col-md-3">
                        <button className="btn btn-primary d-block" onClick={this.handleOpenNewUserModal}>
                            + Novo Usuário
                        </button>
                    </div>
                </div>

                <div className="row m-0">
                    <div className="table">
                        <table>
                            <thead>
                                <tr className='titles'>
                                    <th>Nome</th>
                                    <th>E-mail</th>
                                    <th>Setor</th>
                                    <th></th>
                                </tr>
                            </thead>

                            <tbody>
                                {this.state.users.map((user) => (
                                    <tr className='rows' key={"user-id-"+user.id_user}>
                                        <td>{user.name_user}</td>
                                        <td>{user.email_user}</td>
                                        <td>{user.sector_user}</td>
                                        <td className="settings">
                                            <div className="settings-icons">
                                                <span className="icon view" title="Resetar senha do usuário" onClick={() => this.handleOpenResetUserModal(user)}>
                                                    <GrSettingsOption />
                                                </span>
                                                <span className="icon edit" title="Editar usuário" onClick={() => this.handleOpenEditUserModal(user)}>
                                                    <FaPencilAlt />
                                                </span>
                                                <span className="icon delete" title="Excluir usuário" onClick={() => this.handleOpenDeleteUserModal(user)}>
                                                    <FaTrash />
                                                </span>
                                            </div>
                                        </td>
                                    </tr>
                                ))} 
                            </tbody>
                        </table>     
                    </div>
                    {this.state.isNewUserModalOpen &&
                        <NewUserModal 
                            id="newUserModal"
                            isOpen={this.state.isNewUserModalOpen} 
                            onRequestClose={this.handleCloseNewUserModal}
                        />
                    }
                    
                    {this.state.isDeleteUserModalOpen &&
                        <DeleteUserModal 
                            id="deleteUserModal"
                            isOpen={this.state.isDeleteUserModalOpen} 
                            onRequestClose={this.handleCloseDeleteUserModal}
                            user={this.state.userSelected}
                        />
                    }

                    {this.state.isEditUserModalOpen &&
                        <EditUserModal 
                            id="editUserModal"
                            isOpen={this.state.isEditUserModalOpen} 
                            onRequestClose={this.handleCloseEditUserModal}
                            user={this.state.userSelected}
                        />
                    }

                    {this.state.isResetUserModalOpen &&
                        <ResetUserModal 
                            id="resetUserModal"
                            isOpen={this.state.isResetUserModalOpen} 
                            onRequestClose={this.handleCloseResetUserModal}
                            user={this.state.userSelected}
                        />
                    }
                </div>
            </div>
        );
    }
}

export default Users;
