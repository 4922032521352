import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { api } from '../../../services/api';

import { AiOutlineClose } from "react-icons/ai";

function EditDeclarationTemplateModal ({isOpen, onRequestClose, template}){
    const [btnBlocked, setBtnBlocked] = useState(true);
    
    const [nameTemplate, setNameTemplate] = useState(template.name_template);
    const [message, setMessage] = useState(template.message_template);
    const [imageTemplate, setImageTemplate] = useState();
    const [headerTemplate, setHeaderTemplate] = useState(template.header_template);
    const [signatureTemplate, setSignatureTemplate] = useState(template.signature_template);

    useEffect(() => {
        if(message === '' || imageTemplate === null || nameTemplate === '' || headerTemplate === '' || signatureTemplate === ''){
            setBtnBlocked(true);
        }else {
            setBtnBlocked(false);
        }
        
    }, [message, imageTemplate, nameTemplate, headerTemplate, signatureTemplate]);

    
    useEffect(() => {
        if(imageTemplate === undefined){
            fetchFilesFromURL();
        }
    });

    async function fetchFilesFromURL(){
        setImageTemplate(await fetch(process.env.REACT_APP_BACKEND_URL + '/' + template.image_template).then(r => r.blob()));
    }

    async function updateTempalte(){
        try {
            var formData = new FormData();
            formData.append("id_template_d", template.id_template_d);
            formData.append("message", message);
            formData.append("files", imageTemplate);
            formData.append("name", nameTemplate);
            formData.append("header", headerTemplate);
            formData.append("signature", signatureTemplate);

            var config = {
                headers: {
                    'Content-Type': "multipart/form-data; boundary='files'"
                }
            };

            await api.put('/templated', formData, config);

            onRequestClose(true, true);

        } catch (error) {
            let msg_error = undefined;
            
            if (error && error.response && error.response.data) {
                const errorData = error.response.data;
                if (typeof errorData.error === 'string') {
                  msg_error = errorData.error;
                }
            }

            onRequestClose(true, false, msg_error);
        }
    }

    return (
        <Modal
            ariaHideApp={false}
            overlayClassName="react-modal-overlay"
            className="react-modal-content"
            isOpen={isOpen} 
            onRequestClose={onRequestClose}
        >
            <button 
                className="react-modal-close"
                onClick={onRequestClose} 
            >
                <AiOutlineClose />
            </button>
            <div className="react-modal-title">
                <h4 id="modal-title">Atualizar Template de Declaração de Matrícula</h4>
            </div>

            <div className="react-modal-body">
                <div className="row modal-input-group">
                    <label htmlFor="templateNameEdit" className="row">
                        <h5 className="col-md-2">Nome</h5>
                        <input 
                            type="text" maxLength={50} value={nameTemplate} 
                            onChange={(event) => setNameTemplate(event.target.value)}
                            id="templateNameEdit" className="modal-input col-md-10" 
                        />
                    </label>
                </div>
                <div className="row modal-input-group">
                    <label htmlFor="messageEdit" className="row">
                        <h5 className="col-md-2">Mensagem</h5>
                        <textarea 
                            maxLength={500} value={message} 
                            onChange={(event) => setMessage(event.target.value)}
                            id="messageEdit" className="modal-input col-md-10" 
                        />
                    </label>
                </div>
                <div className="row modal-input-group">
                    <label htmlFor="headerTemplateEdit" className="row">
                        <h5 className="col-md-2">Cabeçalho</h5>
                        <textarea 
                            maxLength={255} value={headerTemplate} 
                            onChange={(event) => setHeaderTemplate(event.target.value)}
                            id="headerTemplateEdit" className="modal-input col-md-10" 
                        />
                    </label>
                </div>
                <div className="row modal-input-group">
                    <label htmlFor="signatureTemplateEdit" className="row">
                        <h5 className="col-md-2">Assinatura</h5>
                        <textarea 
                            maxLength={255} value={signatureTemplate} 
                            onChange={(event) => setSignatureTemplate(event.target.value)}
                            id="signatureTemplateEdit" className="modal-input col-md-10" 
                        />
                    </label>
                </div>

                <div className="row modal-input-group">
                    <div htmlFor="templateEdit" className="label row">
                        <h5 className="col-md-2">Imagem</h5>
                        <div className="file-input col-md-7">
                            <input 
                                type="file" id="templateEdit" className="modal-input" title=' '
                                accept=".png,.jpeg,.jpg" onChange={(event) => {
                                    const fileImage = event.target.files[0];
                                    setImageTemplate(fileImage);
                                }}
                            />
                            <small className='modal-input'>
                                Deixe este campo vazio caso não queira atualizar a foto
                            </small>
                        </div>
                        {imageTemplate !== undefined &&
                            <img src={URL.createObjectURL(imageTemplate)} alt="Imagem de Capa do Template de Certificado" width="200"></img>
                        }
                    </div>
                </div>                
            </div>
            <div className="react-modal-foot">
                <div className="react-modal-btn">
                    <button className="btn btn-red" onClick={onRequestClose} >
                        Cancelar
                    </button>
                    <button 
                        className={btnBlocked ? "btn btn-blocked" : "btn btn-blue"} 
                        onClick={updateTempalte} disabled={btnBlocked}
                        type="button"
                    >
                        Atualizar
                    </button>
                </div>
            </div>
        </Modal>
    )

    
}

export default EditDeclarationTemplateModal;