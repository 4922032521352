import React from 'react'
import { api } from '../../services/api';

import './styles.css' 


class Footer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            address: ''
        };

    }

    async componentDidMount() {
        const response = await api.get('/settings/')
        const settings = response.data.settings
        this.setState({
            address: settings.address_settings,
        })
    }
  
    render() {
        return (
            <div className="footer">
                <div className="block-3 p-3">
                    <div className="address">
                        Endereço: {this.state.address}
                    </div>
                </div>

                <footer className="p-2">
                    © 2021 UNA-SUS/UFMA - Todos os direitos reservados.
                </footer>
            </div>
        );
    }
}

export default Footer