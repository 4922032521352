import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { api } from '../../../services/api';

import { AiOutlineClose } from "react-icons/ai";
import { FaTrash } from "react-icons/fa";

function EditTemplateModal ({isOpen, onRequestClose, template}){
    const [btnBlocked, setBtnBlocked] = useState(true);

    const [nameTemplate, setNameTemplate] = useState(template.name_template);
    const [message, setMessage] = useState(template.message_template);
    const [coverCertificate, setCoverCertificate] = useState();
    const [verseCertificate, setVerseCertificate] = useState(undefined);
    const [color1, setColor1] = useState('#000000');    
    const [color2, setColor2] = useState('#000000');    
    const [color3, setColor3] = useState('#000000');    
    const [color4, setColor4] = useState('#000000');
    const [messageLayout, setMessageLayout] = useState(template.message_layout_template);

    useEffect(() => {
        if(message === '' || coverCertificate === null || nameTemplate === ''){
            setBtnBlocked(true);
        }else {
            setBtnBlocked(false);
        }
    }, [message, coverCertificate, nameTemplate]);

    useEffect(() => {
        const colors = template.colors_template.split(';');
        setColor1(colors[0]);
        setColor2(colors[1]);
        setColor3(colors[2]);
        setColor4(colors[3]);
    }, []);

    useEffect(() => {
        if(coverCertificate === undefined){
            fetchFilesFromURL();
        }
    });

    async function fetchFilesFromURL(){
        setCoverCertificate(await fetch(process.env.REACT_APP_BACKEND_URL + '/' + template.cover_template).then(r => r.blob()));
        if (template.verse_template !== undefined && template.verse_template !== null)
            setVerseCertificate(await fetch(process.env.REACT_APP_BACKEND_URL + '/' + template.verse_template).then(r => r.blob()));
    }

    async function updateTempalte(){
        try {
            var formData = new FormData();
            formData.append("id_template_c", template.id_template_c);
            formData.append("name", nameTemplate);
            formData.append("message", message);
            formData.append("message_layout", messageLayout);
            formData.append("colors", [color1, color2, color3, color4].join(';'));
            formData.append("files", coverCertificate);
            formData.append("files", verseCertificate);

            var config = {
                headers: {
                    'Content-Type': "multipart/form-data; boundary='files'"
                }
            };

            await api.put('/templatec', formData, config);

            onRequestClose(true, true);
            
        } catch (error) {
            let msg_error = undefined;
            
            if (error && error.response && error.response.data) {
                const errorData = error.response.data;
                if (typeof errorData.error === 'string') {
                  msg_error = errorData.error;
                }
            }

            onRequestClose(true, false, msg_error);
        }
    }

    return (
        <Modal
            ariaHideApp={false}
            overlayClassName="react-modal-overlay"
            className="react-modal-content"
            isOpen={isOpen} 
            onRequestClose={onRequestClose}
        >
            <button 
                className="react-modal-close"
                onClick={onRequestClose} 
            >
                <AiOutlineClose />
            </button>
            <div className="react-modal-title">
                <h4 id="modal-title">Atualizar Template de Certificado</h4>
            </div>

            <div className="react-modal-body">
                <div className="row modal-input-group">
                    <label htmlFor="nameEdit" className="row">
                        <h5 className="col-md-2">Nome</h5>
                        <input 
                            type="text" maxLength={50} value={nameTemplate} 
                            onChange={(event) => setNameTemplate(event.target.value)}
                            id="nameEdit" className="modal-input col-md-10" 
                        />
                    </label>
                </div>
                <div className="row modal-input-group">
                    <label htmlFor="messageEdit" className="row">
                        <h5 className="col-md-2">Mensagem</h5>
                        <textarea 
                            maxLength={500} value={message} 
                            onChange={(event) => setMessage(event.target.value)}
                            id="messageEdit" className="modal-input col-md-10" 
                        />
                    </label>
                </div>
                <div className="row modal-input-group">
                    <label htmlFor="messageLayoutTemplate" className="row">
                        <h5 className="col-md-2">Layout da Mensagem</h5>
                        <select id="messageLayoutTemplate" className="modal-input col-md-10" 
                            onChange={(event) => setMessageLayout(parseInt(event.target.value))}
                        >
                            <option value={1} selected={template.message_layout_template === 1}>Layout Horizontal</option>
                            <option value={2} selected={template.message_layout_template === 2}>Layout Tradicional</option>
                        </select>
                    </label>
                </div>
                <div className="row modal-input-group">
                    <div htmlFor="certificateEdit" className="label row">
                        <h5 className="col-md-2">Capa</h5>
                        <div className="file-input col-md-7">
                            <input 
                                type="file" id="certificateEdit" className="modal-input" title=' '
                                accept=".png,.jpeg,.jpg" onChange={(event) => {
                                    const fileCover = event.target.files[0];
                                    setCoverCertificate(fileCover);
                                }}
                            />
                            <small className='modal-input'>
                                Deixe este campo vazio caso não queira atualizar a foto
                            </small>
                        </div>
                        {coverCertificate !== undefined &&
                            <img src={URL.createObjectURL(coverCertificate)} alt="Imagem de Capa do Template de Certificado" width="200"></img>
                        }
                    </div>
                </div>
                <div className="row modal-input-group">
                    <div className="label row">
                        <h5 className="col-md-2">Verso</h5>
                        <div className="file-input col-md-7">
                            <input 
                                type="file" className="modal-input" title=' '
                                accept=".png,.jpeg,.jpg" onChange={(event) => {
                                    const fileVerse = event.target.files[0];
                                    setVerseCertificate(fileVerse);
                                }} 
                            />
                            <small className='modal-input'>
                                Deixe este campo vazio caso não queira atualizar a foto
                            </small>
                        </div>
                        {verseCertificate !== undefined &&
                            <img src={URL.createObjectURL(verseCertificate)} alt="Imagem de Verso do Template de Certificado" width="200"></img>
                        }
                        {verseCertificate !== undefined &&
                            <span className="icon delete" title="Remover Verso" onClick={() => setVerseCertificate(undefined)}>
                                <FaTrash />
                            </span>
                        }
                    </div>
                    
                </div>
                <div className="row modal-input-group">
                    <label htmlFor="colorsCreate" className="row">
                        <h5 className="col-md-2">Cores da Tabela</h5>
                        <input 
                            type="color" id="colorsCreate" className="modal-input col-md-2" title="Cor do título"
                            value={color1} onChange={(event) => setColor1(event.target.value)}
                        />
                        <input 
                            type="color" id="colorsCreate" className="modal-input col-md-2" title="Cor do cabeçalho" 
                            value={color2} onChange={(event) => setColor2(event.target.value)}
                        />
                        <input 
                            type="color" id="colorsCreate" className="modal-input col-md-2" title="Cor do conteúdo"
                            value={color3} onChange={(event) => setColor3(event.target.value)}
                        />
                        <input 
                            type="color" id="colorsCreate" className="modal-input col-md-2" title="Cor do rodapé"
                            value={color4} onChange={(event) => setColor4(event.target.value)}
                        />
                    </label>
                </div>
            </div>
            <div className="react-modal-foot">
                <div className="react-modal-btn">
                    <button className="btn btn-red" onClick={onRequestClose} >
                        Cancelar
                    </button>
                    <button 
                        className={btnBlocked ? "btn btn-blocked" : "btn btn-blue"} 
                        onClick={updateTempalte} disabled={btnBlocked}
                        type="button"
                    >
                        Atualizar
                    </button>
                </div>
            </div>
        </Modal>
    )

    
}

export default EditTemplateModal;