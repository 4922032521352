import React from 'react';
import { api } from '../../../services/api';

import NewSRTemplateModal from '../../SRTemplateModal/NewSRTemplateModal';
import DeleteSRTemplateModal from '../../SRTemplateModal/DeleteSRTemplateModal';
import EditSRTemplateModal from '../../SRTemplateModal/EditSRTemplateModal';
import Alert from '../../Alert';

import { FaTrash, FaPencilAlt } from 'react-icons/fa';

class SchoolRecordTemplates extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            templates: [],
            isNewSRTemplateModalOpen: false,
            isDeleteSRTemplateModalOpen: false,
            isEditSRTemplateModalOpen: false,
            templateSelected: {},
            typeTemplateSelected: "all",
            typeAlert: '',
            messageAlert: '',
            visibleAlert: false
        };
        
        this.listTemplates = this.listTemplates.bind(this);
        
        this.closeAlert = this.closeAlert.bind(this);

        this.handleOpenNewSRTemplateModal = this.handleOpenNewSRTemplateModal.bind(this);
        this.handleCloseNewSRTemplateModal = this.handleCloseNewSRTemplateModal.bind(this);

        this.handleOpenDeleteSRTemplateModal = this.handleOpenDeleteSRTemplateModal.bind(this);
        this.handleCloseDeleteSRTemplateModal = this.handleCloseDeleteSRTemplateModal.bind(this);
    
        this.handleOpenEditSRTemplateModal = this.handleOpenEditSRTemplateModal.bind(this);
        this.handleCloseEditSRTemplateModal = this.handleCloseEditSRTemplateModal.bind(this);
    }

    componentDidMount() {
        this.listTemplates();
    }

    closeAlert(){
        this.setState({visibleAlert: false});
    }
    
    handleOpenNewSRTemplateModal(){
        this.setState({isNewSRTemplateModalOpen: true});
    }

    handleCloseNewSRTemplateModal(operation = false, successOperation = false, messageAlert = undefined){
        this.setState({isNewSRTemplateModalOpen: false});
        if(operation === true){
            if(successOperation === true){
                this.setState({
                    typeAlert: "success",
                    messageAlert: "Template cadastrado com sucesso!",
                    visibleAlert: true
                });
                this.listTemplates();
            }else{
                this.setState({
                    typeAlert: "error",
                    messageAlert: messageAlert !== undefined ? messageAlert : "Não foi possível cadastrar um novo Template. Tente novamente!",
                    visibleAlert: true
                });
            }
        }
    }

    handleOpenDeleteSRTemplateModal(template){
        this.setState({
            templateSelected: template,
            isDeleteSRTemplateModalOpen: true
        });
    }

    handleCloseDeleteSRTemplateModal(operation = false, successOperation = false, messageAlert = undefined){
        this.setState({isDeleteSRTemplateModalOpen: false});
        if(operation === true){
            if(successOperation === true){
                this.setState({
                    typeAlert: "success",
                    messageAlert: "Template excluído com sucesso!",
                    visibleAlert: true,
                    templates: this.state.templates.filter((item) => item.id_template_h !== this.state.templateSelected.id_template_h)
                });
            }else{
                this.setState({
                    typeAlert: "error",
                    messageAlert: messageAlert !== undefined ? messageAlert : "Não foi possível deletar o Template de Histórico. Tente novamente!",
                    visibleAlert: true
                });
            }
        }
    }

    handleOpenEditSRTemplateModal(template){
        this.setState({
            templateSelected: template,
            isEditSRTemplateModalOpen: true
        });
    }

    handleCloseEditSRTemplateModal(operation = false, successOperation = false, messageAlert = undefined){
        this.setState({isEditSRTemplateModalOpen: false});
        if(operation === true) {
            if(successOperation === true) {
                this.setState({
                    typeAlert: "success",
                    messageAlert: "Dados do Template atualizados com sucesso!",
                    visibleAlert: true
                });
                this.listTemplates();
            }else{
                this.setState({
                    typeAlert: "error",
                    messageAlert: messageAlert !== undefined ? messageAlert : "Não foi possível atualizar os dados do Template. Tente novamente!",
                    visibleAlert: true
                });
            }
        }
    }


    async listTemplates(){
        try {
            const response = await api.get('/templateh');
            this.setState({templates: response.data.templates});   
        } catch (error) {
            this.setState({
                typeAlert: "error",
                messageAlert: "Não foi possível listar os Templates de Histórico",
                visibleAlert: true
            });
        }
    }
    
    render() {
        return (
            <div className="row m-0">
                <div className={this.state.visibleAlert === true ? "row m-0 area-alert" : "hide-area"}>    
                    <Alert type={this.state.typeAlert} message={this.state.messageAlert} closeAlert={this.closeAlert} />
                </div>

                <div className="navigation">
                    Configurações/ Templates de Histórico de Curso
                </div>             
                
                <div className="row action-panel">
                    <div className="col-md-8"></div>
                    <div className="btn-area col-md-4">
                        <button className="btn btn-primary d-block" onClick={this.handleOpenNewSRTemplateModal}>
                            + Novo Template
                        </button>
                    </div>
                </div>

                <div className="row m-0">
                    <div className="table">
                        <table>
                            <thead>
                                <tr className='titles'>
                                    <th>Nome</th>
                                    <th>Imagem</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.templates.map((template) => (
                                    <tr className='rows' key={"template-h-id-"+template.id_template_h}>
                                        <td>{template.name_template}</td>
                                        <td>    
                                            {template.image_template !== undefined && template.image_template !== null &&
                                                <img src={process.env.REACT_APP_BACKEND_URL + '/' + template.image_template} alt="Imagem de Capa do Template de Histórico" width="200"></img>
                                            }
                                        </td>
                                        <td className="settings">
                                            <div className="settings-icons">
                                                <span className="icon edit" title="Editar Programa" onClick={() => this.handleOpenEditSRTemplateModal(template)}>
                                                    <FaPencilAlt />
                                                </span>
                                                <span className="icon delete" title="Excluir Programa" onClick={() => this.handleOpenDeleteSRTemplateModal(template)}>
                                                    <FaTrash />
                                                </span>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>     
                    </div>
                </div>

                {this.state.isNewSRTemplateModalOpen &&
                    <NewSRTemplateModal
                        id="newSRTemplateModal"
                        isOpen={this.state.isNewSRTemplateModalOpen} 
                        onRequestClose={this.handleCloseNewSRTemplateModal}
                    />
                }

                {this.state.isDeleteSRTemplateModalOpen &&
                    <DeleteSRTemplateModal
                        id="deleteSRTemplateModal"
                        isOpen={this.state.isDeleteSRTemplateModalOpen}
                        onRequestClose={this.handleCloseDeleteSRTemplateModal}
                        template={this.state.templateSelected}
                    />
                }

                {this.state.isEditSRTemplateModalOpen &&
                    <EditSRTemplateModal
                        id="editSRTemplateModal"
                        isOpen={this.state.isEditSRTemplateModalOpen}
                        onRequestClose={this.handleCloseEditSRTemplateModal}
                        template={this.state.templateSelected}
                    />    
                }

            </div>
        );
    }
}

export default SchoolRecordTemplates;