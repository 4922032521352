import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { api } from '../../../services/api';

import { AiOutlineClose } from "react-icons/ai";
import NewTemplateModal from '../../TemplateModal/NewTemplateModal';
import SelectTemplateModal from '../../TemplateModal/SelectTemplateModal';
import NewDeclarationTemplateModal from '../../DeclarationTemplateModal/NewDeclarationTemplateModal';
import SelectDeclarationTemplateModal from '../../DeclarationTemplateModal/SelectDeclarationTemplateModal';
import NewSRTemplateModal from '../../SRTemplateModal/NewSRTemplateModal';
import SelectSRTemplateModal from '../../SRTemplateModal/SelectSRTemplateModal';


function EditCourseModal ({isOpen, onRequestClose, avas, course}){
    const [btnBlocked, setBtnBlocked] = useState(true);

    const [nameCourse, setNameCourse] = useState(course.Modality.name_modality);
    const [codeCourse, setCodeCourse] = useState(course.code_ava_course);
    const [avaCourses, setAvaCourses] = useState([]);
    const [chCourse, setChCourse] = useState(course.ch);
    const [contentCourse, setContentCourse] = useState(course.content_course);
    const [idAva, setIdAva] = useState(course.Program.id_ava);
    const [idProgram, setIdProgram] = useState(course.id_program);
    const [programs, setPrograms] = useState([]);
    const [startOffer, setStartOffer] = useState(course.Modality.start_offer_period);
    const [endOffer, setEndOffer] = useState(course.Modality.end_offer_period);
    const [templateChosen, setTemplateChosen] = useState(undefined);
    const [declarationTemplateChosen, setDeclarationTemplateChosen] = useState(undefined);
    const [schoolRecordTemplateChosen, setSchoolRecordTemplateChosen] = useState(undefined);

    const [isNewTemplateModalOpen, setIsNewTemplateModalOpen] = useState(false);
    const [isSelectTemplateModalOpen, setIsSelectTemplateModalOpen] = useState(false);

    const [isNewDeclarationTemplateModalOpen, setIsNewDeclarationTemplateModalOpen] = useState(false);
    const [isSelectDeclarationTemplateModalOpen, setIsSelectDeclarationTemplateModalOpen] = useState(false);

    const [isNewSchoolRecordTemplateModalOpen, setIsNewSchoolRecordTemplateModalOpen] = useState(false);
    const [isSelectSchoolRecordTemplateModalOpen, setIsSelectSchoolRecordTemplateModalOpen] = useState(false);

    useEffect(() => {
        if (templateChosen === undefined){
            getTemplate();
            console.log(templateChosen);
        }

        if (declarationTemplateChosen === undefined){
            getDeclarationTemplate();
            console.log(declarationTemplateChosen);
        }

        if (schoolRecordTemplateChosen === undefined){
            getSchoolRecordTemplate();
            console.log(schoolRecordTemplateChosen);
        }
    }, []);

    useEffect(() => {
        if(nameCourse.length === 0 || idProgram === 0 || codeCourse <= 0 || chCourse <= 0){
            setBtnBlocked(true);
        }else {
            setBtnBlocked(false);
        }
    }, [nameCourse, codeCourse, chCourse, idProgram]);

    useEffect(() => {        
        setPrograms([]);
        async function listPrograms(){
            const response = await api.get(`/program/list/${idAva}`);
            setPrograms(response.data.programs);
        }
        if(idAva !== 0) listPrograms();
    }, [idAva]);

    useEffect(() => {
        async function searchCourses(){
            const response = await api.post('external_data/courses', {
                id_ava: parseInt(idAva),
                id_program: parseInt(idProgram)
            });
            setAvaCourses(response.data.courses);
        }

        setAvaCourses([]);
        searchCourses();
    }, [idAva, idProgram]);

    async function updateCourse(){
        try {
            await api.put('/course', {
                id_course: course.id_course,
                id_program: idProgram,
                name_course: nameCourse,
                code_ava_course: codeCourse,
                ch_course: chCourse,
                content_course: contentCourse,
                id_template_c: templateChosen !== undefined ? templateChosen.id_template_c : undefined,
                id_template_d: declarationTemplateChosen !== undefined ? declarationTemplateChosen.id_template_d : undefined,
                id_template_h: schoolRecordTemplateChosen !== undefined ? schoolRecordTemplateChosen.id_template_h : undefined,
                start_offer_period: startOffer,
                end_offer_period: endOffer
            });
            onRequestClose(true, true);
        } catch (error) {
            let msg_error = undefined;
            
            if (error && error.response && error.response.data) {
                const errorData = error.response.data;
                if (typeof errorData.error === 'string') {
                  msg_error = errorData.error;
                }
            }

            onRequestClose(true, false, msg_error);
        }
    }

    async function getTemplate(){
        try {
            const response = await api.get(`/templatec/${course.Modality.id_template_c}`);
            setTemplateChosen(response.data.template);            
        } catch (error) {
            console.log(error);
        } 
    }

    async function getDeclarationTemplate(){
        try {
            const response = await api.get(`/templated/${course.id_template_d}`);
            setDeclarationTemplateChosen(response.data.template);
        } catch (error) {
            console.log(error);
        } 
    }

    async function getSchoolRecordTemplate(){
        try {
            const response = await api.get(`/templateh/${course.id_template_h}`);
            setSchoolRecordTemplateChosen(response.data.template);
        } catch (error) {
            console.log(error);
        } 
    }

    function handleOpenNewTemplateModal(){
        setIsNewTemplateModalOpen(true);
    }

    function handleCloseNewTemplateModal(operation = false, successOperation = undefined, templateChosen = undefined){
        setIsNewTemplateModalOpen(false);
        if(operation === true){
            setTemplateChosen(templateChosen);
        }
    }

    function handleOpenSelectTemplateModal(){
        setIsSelectTemplateModalOpen(true);
    }

    function handleCloseSelectTemplateModal(operation = false, successOperation = undefined, templateChosen = undefined){
        setIsSelectTemplateModalOpen(false);
        if(operation === true){
            setTemplateChosen(templateChosen);
        }
    }

    function handleOpenNewDeclarationTemplateModal(){
        setIsNewDeclarationTemplateModalOpen(true);
    }

    function handleCloseNewDeclarationTemplateModal(operation = false, successOperation = undefined, declarationtemplateChosen = undefined){
        setIsNewDeclarationTemplateModalOpen(false);
        if(operation === true){
            setDeclarationTemplateChosen(declarationtemplateChosen);
        }
    }

    function handleOpenSelectDeclarationTemplateModal(){
        setIsSelectDeclarationTemplateModalOpen(true);
    }

    function handleCloseSelectDeclarationTemplateModal(operation = false, successOperation = undefined, declarationtemplateChosen = undefined){
        setIsSelectDeclarationTemplateModalOpen(false);
        if(operation === true){
            setDeclarationTemplateChosen(declarationtemplateChosen);
        }
    }

    function handleOpenNewSchoolRecordTemplateModal(){
        setIsNewSchoolRecordTemplateModalOpen(true);
    }

    function handleCloseNewSchoolRecordTemplateModal(operation = false, successOperation = undefined, schoolRecordtemplateChosen = undefined){
        setIsNewSchoolRecordTemplateModalOpen(false);
        if(operation === true){
            setSchoolRecordTemplateChosen(schoolRecordtemplateChosen);
        }
    }

    function handleOpenSelectSchoolRecordTemplateModal(){
        setIsSelectSchoolRecordTemplateModalOpen(true);
    }

    function handleCloseSelectSchoolRecordTemplateModal(operation = false, successOperation = undefined, schoolRecordtemplateChosen = undefined){
        setIsSelectSchoolRecordTemplateModalOpen(false);
        if(operation === true){
            setSchoolRecordTemplateChosen(schoolRecordtemplateChosen);
        }
    }

    return (
        <view>
            <Modal
                ariaHideApp={false}
                overlayClassName="react-modal-overlay"
                className="react-modal-content"
                isOpen={isOpen} 
                onRequestClose={onRequestClose}
            >
                <button 
                    className="react-modal-close"
                    onClick={onRequestClose} 
                >
                    <AiOutlineClose />
                </button>
                <div className="react-modal-title">
                    <h4 id="modal-title">Atualizar Dados do Curso</h4>
                </div>

                <div className="react-modal-body">
                    <div className="row modal-input-group">
                        <label htmlFor="courseNameEditw" className="row">
                            <h5 className="col-md-2">Nome</h5>
                            <input 
                                type="text" maxLength={200} value={nameCourse} 
                                onChange={(event) => setNameCourse(event.target.value)}
                                id="courseNameEdit" className="modal-input col-md-10" 
                            />
                        </label>
                    </div>
                    <div className="row modal-input-group">
                        <label htmlFor="courseIdAvaEdit" className="row">
                            <h5 className="col-md-2">AVA</h5>
                            <select id="courseIdAvaEdit" className="modal-input col-md-10" onChange={(event) => setIdAva(parseInt(event.target.value))}>
                                <option value='0'>Selecione um AVA</option>
                                {avas.map((ava) => (
                                    <option key={"ava"+ava.id_ava} selected={ava.id_ava === course.Program.id_ava} value={ava.id_ava}>{ava.name_ava}</option>
                                ))} 
                            </select>
                        </label>
                    </div>
                    <div className="row modal-input-group">
                        <label htmlFor="courseIdProgramEdit" className="row">
                            <h5 className="col-md-2">Programa</h5>
                            <select id="courseIdProgramCreate" className="modal-input col-md-10" onChange={(event) => setIdProgram(parseInt(event.target.value))}>
                                <option value={0}>Selecione um Programa</option>
                                {programs.map((program) => (
                                    <option key={"program"+program.id_program} selected={program.id_program === course.id_program} value={program.id_program}>{program.Modality.name_modality}</option>
                                ))} 
                            </select>
                        </label>
                    </div>
                    <div className="row modal-input-group">
                        <label htmlFor="courseCodeEdit" className="row">
                            <h5 className="col-md-2">Curso no AVA</h5>
                            <select id="courseCodeEdit" className="modal-input col-md-10" 
                                onChange={(event) => setCodeCourse(parseInt(event.target.value))}
                            >
                                <option value={0}>Selecione um Curso Equivalente</option>
                                {avaCourses.map((avaCourse) => (
                                    <option key={"course"+avaCourse.code_ava_course} 
                                            selected={avaCourse.code_ava_course === course.code_ava_course && idAva === course.Program.id_ava} 
                                            value={avaCourse.code_ava_course}
                                    >
                                        {avaCourse.name_course}
                                    </option>
                                ))} 
                            </select>
                        </label>
                    </div>
                    <div className="row modal-input-group">
                        <label htmlFor="courseChCeate" className="row">
                            <h5 className="col-md-2">Carga Horária</h5>
                            <input 
                                type="number" value={chCourse} 
                                onChange={(event) => setChCourse(event.target.value)} 
                                id="courseChCeate" className="modal-input col-md-10" 
                            />
                        </label>
                    </div>
                    <div className="row modal-input-group">
                        <label htmlFor="courseContentEdit" className="row">
                            <h5 className="col-md-2">Conteúdo</h5>
                            <textarea 
                                maxLength={1170} value={contentCourse} 
                                onChange={(event) => setContentCourse(event.target.value)}
                                id="courseContentEdit" className="modal-input col-md-10" 
                            />
                        </label>
                    </div>
                    <div className="row modal-input-group">
                        <label htmlFor="courseStartOfferEdit" className="row">
                            <h5 className="col-md-2">Início da oferta</h5>
                            <input 
                                type="date" value={startOffer} 
                                onChange={(event) => setStartOffer(event.target.value)} 
                                id="courseStartOfferEdit" className="modal-input col-md-10" 
                            />
                        </label>
                    </div>
                    <div className="row modal-input-group">
                        <label htmlFor="courseEndOfferEdit" className="row">
                            <h5 className="col-md-2">Fim da oferta</h5>
                            <input 
                                type="date" value={endOffer} 
                                onChange={(event) => setEndOffer(event.target.value)} 
                                id="courseEndOfferEdit" className="modal-input col-md-10" 
                            />
                        </label>
                    </div> 
                    <div className="row modal-input-group">
                        <label className="row">
                            <h5 className="col-md-2">Certificado</h5>
                                <button className="btn btn-green" onClick={handleOpenNewTemplateModal}>
                                    Novo Template
                                </button>
                                <button className="btn btn-blue" onClick={handleOpenSelectTemplateModal}>
                                    Buscar Template
                                </button>
                                {templateChosen !== undefined &&
                                    <img src={process.env.REACT_APP_BACKEND_URL + '/' + templateChosen.cover_template} alt="Imagem de Capa do Template de Certificado" width="100px"></img>
                                }
                                {templateChosen !== undefined && templateChosen.verse_template !== null &&
                                    <img src={process.env.REACT_APP_BACKEND_URL + '/' + templateChosen.verse_template} alt="Imagem de Verso do Template de Certificado" width="100px"></img>
                                }
                        </label>
                    </div>

                    <div className="row modal-input-group">
                        <label className="row">
                            <h5 className="col-md-2">Declaração</h5>
                                <button className="btn btn-green" onClick={handleOpenNewDeclarationTemplateModal}>
                                    Novo Template
                                </button>
                                <button className="btn btn-blue" onClick={handleOpenSelectDeclarationTemplateModal}>
                                    Buscar Template
                                </button>
                                {declarationTemplateChosen !== undefined && declarationTemplateChosen.image_template !==null &&
                                    <img src={process.env.REACT_APP_BACKEND_URL + '/' + declarationTemplateChosen.image_template} alt="Imagem do Template de Declaração" width="100px"></img>
                                }
                        </label>
                    </div>

                    <div className="row modal-input-group">
                        <label className="row">
                            <h5 className="col-md-2">Histórico</h5>
                                <button className="btn btn-green" onClick={handleOpenNewSchoolRecordTemplateModal}>
                                    Novo Template
                                </button>
                                <button className="btn btn-blue" onClick={handleOpenSelectSchoolRecordTemplateModal}>
                                    Buscar Template
                                </button>
                                {schoolRecordTemplateChosen !== undefined && schoolRecordTemplateChosen.image_template !==null &&
                                    <img src={process.env.REACT_APP_BACKEND_URL + '/' + schoolRecordTemplateChosen.image_template} alt="Imagem do Template de Declaração" width="100px"></img>
                                }
                        </label>
                    </div>
                </div>
                <div className="react-modal-foot">            
                    <div className="react-modal-btn">
                        <button className="btn btn-red" onClick={onRequestClose} >
                            Cancelar
                        </button>
                        <button 
                            className={btnBlocked ? "btn btn-blocked" : "btn btn-blue"} 
                            onClick={updateCourse} disabled={btnBlocked}
                            type="button"
                        >
                            Atualizar
                        </button>
                    </div>
                </div>
            </Modal>
            
            {isNewTemplateModalOpen &&
                    <NewTemplateModal
                        id="newTemplateModal"
                        isOpen={isNewTemplateModalOpen} 
                        onRequestClose={handleCloseNewTemplateModal}
                    />
            }

            {isSelectTemplateModalOpen &&
                <SelectTemplateModal
                    id="selectTemplateModal"
                    isOpen={isSelectTemplateModalOpen}
                    onRequestClose={handleCloseSelectTemplateModal}
                />
            }

            {isNewDeclarationTemplateModalOpen &&
                <NewDeclarationTemplateModal
                    id="newDeclarationTemplateModal"
                    isOpen={isNewDeclarationTemplateModalOpen} 
                    onRequestClose={handleCloseNewDeclarationTemplateModal}
                />
            }

            {isSelectDeclarationTemplateModalOpen &&
                <SelectDeclarationTemplateModal
                    id="selectDeclarationTemplateModal"
                    isOpen={isSelectDeclarationTemplateModalOpen}
                    onRequestClose={handleCloseSelectDeclarationTemplateModal}
                />
            }

            {isNewSchoolRecordTemplateModalOpen &&
                <NewSRTemplateModal
                    id="newSchoolRecordTemplateModal"
                    isOpen={isNewSchoolRecordTemplateModalOpen} 
                    onRequestClose={handleCloseNewSchoolRecordTemplateModal}
                />
            }

            {isSelectSchoolRecordTemplateModalOpen &&
                <SelectSRTemplateModal
                    id="selectSchoolRecordTemplateModal"
                    isOpen={isSelectSchoolRecordTemplateModalOpen}
                    onRequestClose={handleCloseSelectSchoolRecordTemplateModal}
                />
            }

        </view>
    );
    
}

export default EditCourseModal;