import React from 'react';

import { AiOutlineClose } from "react-icons/ai";
import { BsCheck2Circle, BsExclamationCircle } from "react-icons/bs";

import './styles.css';


function Alert ({ type, message, closeAlert }){
    return (
        <div className={type === "error" ? "alert alert-danger" : "alert alert-success"} >
            <div className="alert-content">
                <span className="alert-icon">
                    {type === "error" ? (<BsExclamationCircle />) : <BsCheck2Circle /> }
                </span>
                <span className="alert-text">
                    {message}
                </span>
            </div>
            <button className="alert-close" onClick={closeAlert}>
                <AiOutlineClose />
            </button>
        </div>
    );
}

export default Alert;