import React from 'react';
import { FaFileDownload } from 'react-icons/fa';
import './style.css';

function CourseDocumentDownloadDropdown({ generateTestDocument, course }){
  const documentOptions = [
    { type: 'Certificado', name: 'Exemplo de Certificado', template: course.Modality.id_template_c !== null },
    { type: 'Declaração', name: 'Exemplo de Declaração',  template: course.id_template_d !== null},
    { type: 'Histórico', name: 'Exemplo de Histórico', template: course.id_template_h !== null},
  ];

  const handleDocumentChange = (type) => {
    generateTestDocument(course.code_ava_course, course.Program.id_ava, type);
  };

  return (
    <div className="dropdown">
      <div className="btn-download">
        <FaFileDownload />
      </div>
      <div className="dropdown-content">
        {documentOptions.map((document) => (
          document.template && 
            <div
              className={`dropdown-item`}
              key={document.type+"_"+course.id_course}
              onClick={() => document.template && handleDocumentChange(document.type)}
            >
             {document.name}
            </div>
        ))}
      </div>
    </div>
  );
};

export default CourseDocumentDownloadDropdown;
