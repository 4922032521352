import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { api } from '../../../services/api';

import { AiOutlineClose } from "react-icons/ai";

function NewUserModal ({isOpen, onRequestClose}){
    const [btnBlocked, setBtnBlocked] = useState(true);

    const [checkActive, setCheckActive] = useState(false);
    const [checkAdmin, setCheckAdmin] = useState(false);

    const [nameUser, setNameUser] = useState('');
    const [emailUser, setEmailUser] = useState('');
    const [sectorUser, setSectorUser] = useState('');

    useEffect(() => {
        if(nameUser.length === 0 || emailUser.length === 0 || sectorUser.length === 0){
            setBtnBlocked(true);
        }else {
            setBtnBlocked(false);
        }
    }, [nameUser, emailUser, sectorUser]);

    async function registerUser(){
        try {
            await api.post('/user', {
                name: nameUser,
                email: emailUser,
                password: '123456', 
                sector: sectorUser,
                active: checkActive,
                admin: checkAdmin
            });
            onRequestClose(true, true);
        } catch (error) {
            let msg_error = undefined;
            
            if (error && error.response && error.response.data) {
                const errorData = error.response.data;
                if (typeof errorData.error === 'string') {
                  msg_error = errorData.error;
                }
            }

            onRequestClose(true, false, msg_error);
        }
    }

    return (
        <Modal
            ariaHideApp={false}
            overlayClassName="react-modal-overlay"
            className="react-modal-content"
            isOpen={isOpen} 
            onRequestClose={onRequestClose}
        >
            <button 
                className="react-modal-close"
                onClick={onRequestClose} 
            >
                <AiOutlineClose />
            </button>
            <div className="react-modal-title">
                <h4 id="modal-title">Cadastro de Novo Usuário</h4>
            </div>

            <div className="react-modal-body">
                <div className="row modal-input-group">
                    <label htmlFor="userNameRegister" className="row">
                        <h5 className="col-md-2">Nome</h5>
                        <input 
                            type="text" maxLength={50} value={nameUser} 
                            onChange={(event) => setNameUser(event.target.value)}
                            id="userNameRegister" className="modal-input col-md-10" 
                        />
                    </label>
                </div>
                <div className="row modal-input-group">
                    <label htmlFor="userEmailRegister" className="row">
                        <h5 className="col-md-2">E-mail</h5>
                        <input 
                            type="text" maxLength={80} value={emailUser} 
                            onChange={(event) => setEmailUser(event.target.value)} 
                            id="userEmailRegister" className="modal-input col-md-10" 
                        />
                    </label>
                </div>
                <div className="row modal-input-group">
                    <label htmlFor="userSectorRegister" className="row">
                        <h5 className="col-md-2">Setor</h5>
                        <input 
                            type="text" maxLength={50} value={sectorUser} 
                            onChange={(event) => setSectorUser(event.target.value)} 
                            id="userSectorRegister" className="modal-input col-md-10" 
                        />
                    </label>
                </div>
                
                <div className="row">
                    <div className="col-md-2"></div>
                    <div className="col-md-10">
                        <div className="modal-checkbox">
                            <input type="checkbox" id="checkboxActive" checked={checkActive}
                                onChange={(event) => setCheckActive(event.target.checked)} />
                            <label htmlFor="checkboxActive">
                                <h5>Ativo</h5>
                            </label>
                        </div>
                        <div className="modal-checkbox">
                            <input type="checkbox" id="checkboxAdmin" checked={checkAdmin}
                                onChange={(event) => setCheckAdmin(event.target.checked)} />
                            <label htmlFor="checkboxAdmin">
                                <h5>Administrador</h5>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div className="react-modal-foot">
                <div className="react-modal-btn">
                    <button className="btn btn-red" onClick={onRequestClose} >
                        Cancelar
                    </button>
                    <button 
                        className={btnBlocked ? "btn btn-blocked" : "btn btn-blue"} 
                        onClick={registerUser} disabled={btnBlocked}
                        type="button"
                    >
                        Cadastrar
                    </button>
                </div>
            </div>
        </Modal>
    );
    
}

export default NewUserModal;