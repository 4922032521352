import React from 'react';
import { api } from '../../../services/api';

import DeleteAvaModal from '../../AvaModal/DeleteAvaModal';
import NewAvaModal from '../../AvaModal/NewAvaModal';
import EditAvaModal from '../../AvaModal/EditAvaModal';
import Alert from '../../Alert';
import AvaCredentialModal from '../../AvaModal/AvaCredentialModal';

import { FaTrash, FaPencilAlt, FaDatabase } from 'react-icons/fa';

class Avas extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            avas: [],
            isNewAvaModalOpen: false,
            isDeleteAvaModalOpen: false,
            isEditAvaModalOpen: false,
            isAvaCredentialModalOpen: false,
            avaSelected: {},
            typeAlert: '',
            messageAlert: '',
            visibleAlert: false
        };
        
        this.listAvas = this.listAvas.bind(this);
        this.closeAlert = this.closeAlert.bind(this);

        this.handleOpenNewAvaModal = this.handleOpenNewAvaModal.bind(this);
        this.handleCloseNewAvaModal = this.handleCloseNewAvaModal.bind(this);

        this.handleOpenDeleteAvaModal = this.handleOpenDeleteAvaModal.bind(this);
        this.handleCloseDeleteAvaModal = this.handleCloseDeleteAvaModal.bind(this);

        this.handleOpenEditAvaModal = this.handleOpenEditAvaModal.bind(this);
        this.handleCloseEditAvaModal = this.handleCloseEditAvaModal.bind(this);

        this.handleOpenAvaCredentialModal = this.handleOpenAvaCredentialModal.bind(this);
        this.handleCloseAvaCredentialModal = this.handleCloseAvaCredentialModal.bind(this);
    }

    componentDidMount() {
       this.listAvas();            
    }  

    closeAlert(){
        this.setState({visibleAlert: false});
    }
    
    handleOpenNewAvaModal(){
        this.setState({isNewAvaModalOpen: true});
    }

    handleCloseNewAvaModal(operation = false, successOperation = false, messageAlert = undefined){
        this.setState({isNewAvaModalOpen: false});
        if(operation === true){
            if(successOperation === true){
                this.setState({
                    typeAlert: "success",
                    messageAlert: "AVA cadastrado com sucesso!",
                    visibleAlert: true
                });
                this.listAvas();
            }else{
                this.setState({
                    typeAlert: "error",
                    messageAlert: messageAlert !== undefined ? messageAlert : "Não foi possível cadastrar um novo AVA. Tente novamente!",
                    visibleAlert: true
                });
            }
        }
    }

    handleOpenDeleteAvaModal(ava){
        this.setState({
            avaSelected: ava, 
            isDeleteAvaModalOpen: true
        });
    }

    handleCloseDeleteAvaModal(operation = false, successOperation = false, messageAlert = undefined){
        this.setState({isDeleteAvaModalOpen: false});
        if(operation === true){
            if(successOperation === true){
                this.setState({
                    typeAlert: "success",
                    messageAlert: "Ava excluído com sucesso!",
                    visibleAlert: true,
                    avas: this.state.avas.filter((item) => item.id_ava !== this.state.avaSelected.id_ava)
                });
            }else{
                this.setState({
                    typeAlert: "error",
                    messageAlert: messageAlert !== undefined ? messageAlert : "Não foi possível deletar o Ava. Tente novamente!",
                    visibleAlert: true
                });
            }
        }
    }

    handleOpenEditAvaModal(ava){
        this.setState({
            avaSelected: ava,
            isEditAvaModalOpen: true
        });
    }

    handleCloseEditAvaModal(operation = false, successOperation = false, messageAlert = undefined){
        this.setState({isEditAvaModalOpen: false});
        if(operation === true){
            if(successOperation === true){
                this.setState({
                    typeAlert: "success",
                    messageAlert: "Dados do AVA atualizados com sucesso!",
                    visibleAlert: true
                });
                this.listAvas();
            }else{
                this.setState({
                    typeAlert: "error",
                    messageAlert: messageAlert !== undefined ? messageAlert : "Não foi possível atualizar os dados do AVA. Tente novamente!",
                    visibleAlert: true
                });
            }
        }
    }

    handleOpenAvaCredentialModal(ava){
        this.setState({
            avaSelected: ava,
            isAvaCredentialModalOpen: true
        });
    }

    handleCloseAvaCredentialModal(operation = false, successOperation = false, messageAlert = undefined){
        this.setState({isAvaCredentialModalOpen: false});
        if(operation === true){
            if(successOperation === true){
                this.setState({
                    typeAlert: "success",
                    messageAlert: "Dados do AVA atualizados com sucesso!",
                    visibleAlert: true
                });
                this.listAvas();
            }else{
                this.setState({
                    typeAlert: "error",
                    messageAlert: messageAlert !== undefined ? messageAlert : "Não foi possível atualizar credenciais do Banco de Dados do AVA. Tente novamente!",
                    visibleAlert: true
                });
            }
        }
    }

    async listAvas(){
        const response = await api.get('/ava/');
        this.setState({avas: response.data.avas});
    }
    
    render() {
        return (
            <div className="row m-0">
                <div className={this.state.visibleAlert === true ? "row m-0 area-alert" : "hide-area"}>    
                    <Alert type={this.state.typeAlert} message={this.state.messageAlert} closeAlert={this.closeAlert} />
                </div>

                <div className="navigation">
                    Sistema / Configurações dos AVAs
                </div>

                <div className="row action-panel">
                    <div className="col-md-8"></div>
                    <div className="btn-area col-md-4">
                        <button className="btn btn-primary d-block" onClick={this.handleOpenNewAvaModal}>
                            + Novo AVA
                        </button>
                    </div>
                </div>

                <div className="row m-0">
                    <div className="table">
                        <table>
                            <thead>
                                <tr className='titles'>
                                    <th>Código</th>
                                    <th>AVA</th>
                                    <th>Status</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.avas.map((ava) => (
                                    <tr className='rows' key={"ava-id-"+ava.id_ava}>
                                        <td>{ava.id_ava}</td>
                                        <td>{ava.name_ava}</td>
                                        <td>{ava.status_ava === true ? "Ativo" : "Inativo"}</td>
                                        <td className="settings">
                                            <div className="settings-icons">
                                                <span className="icon database" title="Credenciais do Banco de Dados" onClick={() => this.handleOpenAvaCredentialModal(ava)}>
                                                    <FaDatabase />
                                                </span>
                                                <span className="icon edit" title="Editar AVA" onClick={() => this.handleOpenEditAvaModal(ava)}>
                                                    <FaPencilAlt />
                                                </span>
                                                <span className="icon delete" title="Excluir AVA" onClick={() => this.handleOpenDeleteAvaModal(ava)}>
                                                    <FaTrash />
                                                </span>
                                            </div>
                                        </td>
                                    </tr>
                                ))} 
                            </tbody>
                        </table>     
                    </div>
                    {this.state.isNewAvaModalOpen &&
                        <NewAvaModal
                            id="newAvaModal"
                            isOpen={this.state.isNewAvaModalOpen} 
                            onRequestClose={this.handleCloseNewAvaModal}
                        />
                    }

                    {this.state.isDeleteAvaModalOpen &&
                        <DeleteAvaModal
                            id="deleteAvaModal"
                            isOpen={this.state.isDeleteAvaModalOpen} 
                            onRequestClose={this.handleCloseDeleteAvaModal}
                            ava={this.state.avaSelected}
                        />
                    }

                    {this.state.isEditAvaModalOpen &&
                        <EditAvaModal
                            id="editAvaModal"
                            isOpen={this.state.isEditAvaModalOpen} 
                            onRequestClose={this.handleCloseEditAvaModal}
                            ava={this.state.avaSelected}
                        />
                    }

                    {this.state.isAvaCredentialModalOpen &&
                        <AvaCredentialModal
                            id="avaCredentialModal"
                            isOpen={this.state.isAvaCredentialModalOpen} 
                            onRequestClose={this.handleCloseAvaCredentialModal}
                            ava={this.state.avaSelected}
                        />
                    }

                </div>
                
            </div>
        );
    }
}

export default Avas;
