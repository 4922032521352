import React from 'react';
import { api } from '../../services/api';
import { FaSearch, FaCheckDouble } from 'react-icons/fa';
import FileSaver from 'file-saver';

import Loader from '../Loader';

import './style.css';


class DocumentValidation extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            loadingDocument: false,
            errorMessage: "",
        };

        this.generateDocument = this.generateDocument.bind(this);
        this.loadingDocument = this.loadingDocument.bind(this)
    }

    loadingDocument(){
        this.setState({ loadingDocument: true, errorMessage: "" });
        this.generateDocument()
    }

    async generateDocument(){
        let rota = "/certificate/course";
        const document_type = this.props.item.id_document_type;
        if (document_type === 1 && this.props.type === "program"){
            rota = "/certificate/program";
        } else if (document_type === 2){
            rota = "/report";
        } else if (document_type === 3){
            rota = "/declaration";
        }

        try {
            const response = await api.get(`${rota}?code=${this.props.item.hash}` , { responseType: 'blob' });
            const blob = new Blob([response.data], { type: 'application/pdf' });
            const filename = `Document-${this.props.item.hash}.pdf`;
            FileSaver.saveAs(blob, filename);

        } catch (error) {
            this.setState({ errorMessage: "Erro ao emitir o documento. Tente novamente!" });
        }
        this.setState({ loadingDocument: false });
    }

    render(){
        return (
            <div className="box-document">
                <div className="icon">
                    <span>
                        <FaCheckDouble />
                    </span>
                </div>
                <div className="message-validate">
                    Documento válido e emitido pelo SAITE Certificação!
                </div>

                <div className="download-document" onClick={this.loadingDocument}>
                    <div cldivsName="download">
                        <span><FaSearch /></span>
                        <strong>Visualizar documento</strong>
                    </div>
                </div>
                <span className="error-msg">{this.state.errorMessage || ""}</span>
                {this.state.loadingDocument ? <Loader /> : null}
            </div>
        )
    }
}

export default DocumentValidation;
