import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { api } from '../../../services/api';

import { AiOutlineClose } from "react-icons/ai";
import NewTemplateModal from '../../TemplateModal/NewTemplateModal';
import SelectTemplateModal from '../../TemplateModal/SelectTemplateModal';


function EditProgramModal ({isOpen, onRequestClose, avas, program}){
    const [btnBlocked, setBtnBlocked] = useState(true);

    const [nameProgram, setNameProgram] = useState(program.Modality.name_modality);
    const [idAva, setIdAva] = useState(program.id_ava);
    const [codeProgram, setCodeProgram] = useState(program.code_ava_program);
    const [avaPrograms, setAvaPrograms] = useState([]);
    const [quantCourses, setQuantCourses] = useState(program.quant_courses);
    const [startOffer, setStartOffer] = useState(program.Modality.start_offer_period);
    const [endOffer, setEndOffer] = useState(program.Modality.end_offer_period);
    const [templateChosen, setTemplateChosen] = useState(undefined);

    const [isNewTemplateModalOpen, setIsNewTempalteModalOpen] = useState(false);
    const [isSelectTemplateModalOpen, setIsSelectTempalteModalOpen] = useState(false);

    useEffect(() => {
        if (templateChosen === undefined){
            getTemplate();
            console.log(templateChosen);
        }
    }, []);

    useEffect(() => {
        if(nameProgram.length === 0 || idAva === 0 || codeProgram <= 0){
            setBtnBlocked(true);
        }else {
            setBtnBlocked(false);
        }
    }, [idAva, nameProgram, codeProgram]);

    useEffect(() => {
        async function searchPrograms(){
            const response = await api.post('external_data/programs', {
                id_ava: parseInt(idAva)
            });
            setAvaPrograms(response.data.programs);
        }

        setAvaPrograms([]);
        searchPrograms();
    }, [idAva]);

    async function updateProgram(){
        try {
            console.log("Aquiiii!");
            await api.put('/program/', {
                id_program: program.id_program,
                id_ava: parseInt(idAva),
                name_program: nameProgram,
                code_ava_program: codeProgram,
                start_offer_period: startOffer,
                end_offer_period: endOffer,
                id_template_c: templateChosen !== undefined ? templateChosen.id_template_c : undefined,
                quant_courses: quantCourses
            });
            onRequestClose(true, true);
        } catch (error) {
            let msg_error = undefined;
            
            if (error && error.response && error.response.data) {
                const errorData = error.response.data;
                if (typeof errorData.error === 'string') {
                  msg_error = errorData.error;
                }
            }

            onRequestClose(true, false, msg_error);
        }
    }

    async function getTemplate(){
        try {
            const response = await api.get(`/templatec/${program.Modality.id_template_c}`);
            setTemplateChosen(response.data.template);
        } catch (error) {
            console.log(error);
        } 
    }

    function handleOpenNewTemplateModal(){
        setIsNewTempalteModalOpen(true);
    }

    function handleCloseNewTemplateModal(operation = false, successOperation = undefined, templateChosen = undefined){
        setIsNewTempalteModalOpen(false);
        if(operation === true){
            setTemplateChosen(templateChosen);
        }
    }

    function handleOpenSelectTemplateModal(){
        setIsSelectTempalteModalOpen(true);
    }

    function handleCloseSelectTemplateModal(operation = false, successOperation = undefined, templateChosen = undefined){
        setIsSelectTempalteModalOpen(false);
        if(operation === true){
            setTemplateChosen(templateChosen);
        }
    }

    return (
        <view>
            <Modal
                ariaHideApp={false}
                overlayClassName="react-modal-overlay"
                className="react-modal-content"
                isOpen={isOpen} 
                onRequestClose={onRequestClose}
            >
                <button 
                    className="react-modal-close"
                    onClick={onRequestClose} 
                >
                    <AiOutlineClose />
                </button>
                <div className="react-modal-title">
                    <h4 id="modal-title">Atualizar Dados de Programa</h4>
                </div>

                <div className="react-modal-body">
                    <div className="row modal-input-group">
                        <label htmlFor="programNameEdit" className="row">
                            <h5 className="col-md-3">Nome</h5>
                            <input 
                                type="text" maxLength={200} value={nameProgram} 
                                onChange={(event) => setNameProgram(event.target.value)}
                                id="programNameEdit" className="modal-input col-md-9" 
                            />
                        </label>
                    </div>

                    <div className="row modal-input-group">
                        <label htmlFor="programIdAvaEdit" className="row">
                            <h5 className="col-md-3">AVA</h5>    
                            <select id="programIdAvaEdit" className="modal-input col-md-9" onChange={(event) => setIdAva(parseInt(event.target.value))}>
                                <option value='0'>Selecione um AVA</option>
                                {avas.map((ava) => (
                                    <option key={"ava"+ava.id_ava} selected={ava.id_ava === program.id_ava} value={ava.id_ava}>{ava.name_ava}</option>
                                ))} 
                            </select>
                        </label>
                    </div>
                    
                    <div className="row modal-input-group">
                        <label htmlFor="programCodeEdit" className="row">
                            <h5 className="col-md-3">Programa no AVA</h5>
                            <select id="programCodeEdit" className="modal-input col-md-9" 
                                onChange={(event) => setCodeProgram(parseInt(event.target.value))}
                            >
                                <option value={0}>Selecione um Programa Equivalente</option>
                                {avaPrograms.map((avaProgram) => (
                                    <option 
                                        key={"program"+avaProgram.code_ava_program} 
                                        selected={avaProgram.code_ava_program === program.code_ava_program && idAva === program.id_ava}
                                        value={avaProgram.code_ava_program}
                                    >
                                        {avaProgram.name_program}
                                    </option>
                                ))} 
                            </select>
                        </label>
                    </div>

                    <div className="row modal-input-group">
                        <label htmlFor="quantCoursesEdit" className="row">
                            <h5 className="col-md-3">Quantidade de Cursos</h5>
                            <input 
                                type="number" value={quantCourses} 
                                onChange={(event) => setQuantCourses(event.target.value)} 
                                id="quantCoursesEdit" className="modal-input col-md-9" 
                            />
                        </label>
                    </div>
                    
                    <div className="row modal-input-group">
                        <label htmlFor="programStartOffer" className="row">
                            <h5 className="col-md-3">Início da oferta</h5>
                            <input 
                                type="date" value={startOffer} 
                                onChange={(event) => setStartOffer(event.target.value)} 
                                id="programStartOffer" className="modal-input col-md-9" 
                            />
                        </label>
                    </div>

                    <div className="row modal-input-group">
                        <label htmlFor="programEndOffer" className="row">
                            <h5 className="col-md-3">Fim da oferta</h5>
                            <input 
                                type="date" value={endOffer} 
                                onChange={(event) => setEndOffer(event.target.value)} 
                                id="programEndOffer" className="modal-input col-md-9" 
                            />
                        </label>
                    </div>

                    <div className="row modal-input-group">
                        <label htmlFor="programTemplateCreate" className="row">
                            <h5 className="col-md-3">Template</h5>
                                <button className="btn btn-green" onClick={handleOpenNewTemplateModal}>
                                    Criar Novo Template
                                </button>
                                <button className="btn btn-blue" onClick={handleOpenSelectTemplateModal}>
                                    Buscar Template
                                </button>
                                {templateChosen !== undefined &&
                                    <img src={process.env.REACT_APP_BACKEND_URL + '/' + templateChosen.cover_template} alt="Imagem de Capa do Template de Certificado" width="100px"></img>
                                }
                                {templateChosen !== undefined &&  templateChosen.verse_template !== null &&
                                    <img src={process.env.REACT_APP_BACKEND_URL + '/' + templateChosen.verse_template} alt="Imagem de Verso do Template de Certificado" width="100px"></img>
                                }
                        </label>
                    </div>
                </div>
                <div className="react-modal-foot">
                    <div className="react-modal-btn">
                        <button className="btn btn-red" onClick={onRequestClose} >
                            Cancelar
                        </button>
                        <button 
                            className={btnBlocked ? "btn btn-blocked" : "btn btn-blue"} 
                            onClick={updateProgram} disabled={btnBlocked}
                            type="button"
                        >
                            Atualizar
                        </button>
                    </div>
                </div>
            </Modal>
        
            {isNewTemplateModalOpen &&
                <NewTemplateModal
                    id="newTemplateModal"
                    isOpen={isNewTemplateModalOpen} 
                    onRequestClose={handleCloseNewTemplateModal}
                />
            }

            {isSelectTemplateModalOpen &&
                <SelectTemplateModal
                    id="selectTemplateModal"
                    isOpen={isSelectTemplateModalOpen}
                    onRequestClose={handleCloseSelectTemplateModal}
                />
            }

        </view>
    );
    
}

export default EditProgramModal;