import React from "react";
import { Link } from "react-router-dom";

import "./styles.css";
import "../../styles/Validation&Login.css";

import LogoUFMA from "../../assets/ufma.png";
import { api } from "../../services/api";
import DocumentValidation from "../../components/DocumentValidation";

const Validation = () => {
  const [code, setCode] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState("");
  const [showDocumentValidation, setShowDocumentValidation] = React.useState(false);
  const [document, setDocument] = React.useState({
    type: "",
    item: ""
  });
  
  const [settings, setSettings] = React.useState({
    address: "",
    manual: "",
    presentation: "",
  });

  function validateDocument(){
    setShowDocumentValidation(false);
  }

  async function getSettings() {
    try {
      const response = await api.get("/settings");

      setSettings({
        presentation: response.data.settings.presentation_settings,
        address: response.data.settings.address_settings,
        manual: response.data.settings.manual_settings,
      });

    } catch (error) {
      console.log(JSON.stringify(error));
    }
  }

  React.useEffect(() => {
    getSettings();
  }, []);

  async function validateCode(e) {
    e.preventDefault();
    setErrorMessage("");

    try {
      const response = await api.get(`/document/validate?code=${code}`);
      console.log(process.env.REACT_APP_BACKEND_URL);
      console.log(response.data);
      if(response.data.message === "Documento válido"){
        setDocument({
          type: response.data.modality,
          item: response.data.item
        });
        setShowDocumentValidation(true);
      } else if (response.data.message){
        setErrorMessage(response.data.message);
      } else{
        setErrorMessage("Não foi possível validar o documento. Por favor, verifique sua conexão e tente novamente mais tarde!");
      }
    } catch (error) {
      console.log(JSON.stringify(error));
      setErrorMessage("Falha ao tentar validar o documento!");
    }
  }

  return (
    <div className="container-validation background-blue p-0 grid-container">
      <div className="row m-0">
        <div className="col-md-6 block-1">
          <div className="title">SAITE Certificação</div>
          <div className="text">{settings.presentation}</div>
        </div>
        <div className="col-md-6 block-2">
          <div className="icon">
            <i className="fas fa-adjust"></i>
          </div>

          <div className="title">Validação</div>
          <div className={showDocumentValidation === true ? "hide-area" : "show-area"}>
            <div className="text">
              Caso você queira verificar a autenticidade de um documento, informe,
              abaixo, o código do documento, e clique em validar.
            </div>
            <form onSubmit={validateCode}>
              <input
                type="text"
                className="d-block"
                value={code}
                name="code"
                onChange={(e) => setCode(e.target.value)}
              ></input>
              <button type="submit" className="btn btn-success d-block validar">
                Valide aqui
              </button>
              <span className="error-msg">{errorMessage || ""}</span>
            </form>
          </div>
          
          <div className={showDocumentValidation === true ? "show-area" : "hide-area"} >
            <DocumentValidation item={document.item} type={document.type} />

            <button onClick={validateDocument} className="btn btn-success d-block validar">
              Validar outro documento
            </button>
          </div>


          <Link
            to="/login"
            className="btn btn-primary d-block acessar-sistema"
          >
            Acessar Sistema
          </Link>

          <a
            href={settings.manual}
            target="_blank"
            rel="noreferrer"
            className="btn btn-secondary d-block tutorial"
          >
            Acesse o tutorial do SAITE Certificação
          </a>
        </div>
      </div>

      <div className="row m-0 block-3">
        <img className="logo-ufma" src={LogoUFMA} alt="" />
        <div className="address">Endereço: {settings.address}</div>
      </div>

      <footer className="row m-0">
        © 2021 UNA-SUS/UFMA - Todos os direitos reservados.
      </footer>
    </div>
  );
};

export { Validation };
