import React from 'react';
import { api } from '../../../services/api';

import NewCourseModal from '../../CourseModal/NewCourseModal';
import DeleteCourseModal from '../../CourseModal/DeleteCourseModal';
import EditCourseModal from '../../CourseModal/EditCourseModal';
import CourseDocumentDownloadDropdown from '../../CourseDocumentDownloadDropdown';
import Alert from '../../Alert';
import FileSaver from 'file-saver';
import Loader from '../../Loader';

import { FaTrash, FaPencilAlt } from 'react-icons/fa';

class Courses extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            courses: [],            
            courseSelected: {},
            programs: [],
            programSelected: 0,
            avas: [],
            avaSelected: 0,
            typeAlert: '',
            messageAlert: '',
            visibleAlert: false,
            loadingDocument: false
        };
        
        this.closeAlert = this.closeAlert.bind(this);
        this.onChangeAva = this.onChangeAva.bind(this);
        this.listAvas = this.listAvas.bind(this);
        this.listCourses = this.listCourses.bind(this);

        this.generateTestDocument = this.generateTestDocument.bind(this);

        this.handleOpenNewCourseModal = this.handleOpenNewCourseModal.bind(this);
        this.handleCloseNewCourseModal = this.handleCloseNewCourseModal.bind(this);

        this.handleOpenDeleteCourseModal = this.handleOpenDeleteCourseModal.bind(this);
        this.handleCloseDeleteCourseModal = this.handleCloseDeleteCourseModal.bind(this);

        this.handleOpenEditCourseModal = this.handleOpenEditCourseModal.bind(this);
        this.handleCloseEditCourseModal = this.handleCloseEditCourseModal.bind(this);
    }

    componentDidMount() {
        this.listCourses();
        this.listAvas();          
    }

    closeAlert(){
        this.setState({visibleAlert: false});
    }

    handleOpenNewCourseModal(){
        this.setState({isNewCourseModalOpen: true});
    }

    handleCloseNewCourseModal(operation = false, successOperation = false, messageAlert = undefined){
        this.setState({isNewCourseModalOpen: false});
        if(operation === true){
            if(successOperation === true){
                this.setState({
                    typeAlert: "success",
                    messageAlert: "Curso cadastrado com sucesso!",
                    visibleAlert: true
                });
                this.listCourses();
            }else{
                this.setState({
                    typeAlert: "error",
                    messageAlert: messageAlert !== undefined ? messageAlert : "Não foi possível cadastrar um novo Curso. Tente novamente!",
                    visibleAlert: true
                });
            }
        }
    }

    handleOpenDeleteCourseModal(course){
        this.setState({
            courseSelected: course, 
            isDeleteCourseModalOpen: true
        });
    }

    handleCloseDeleteCourseModal(operation = false, successOperation = false, messageAlert = undefined){
        this.setState({isDeleteCourseModalOpen: false});
        if(operation === true){
            if(successOperation === true){
                this.setState({
                    typeAlert: "success",
                    messageAlert: "Curso excluído com sucesso!",
                    visibleAlert: true,
                    courses: this.state.courses.filter((item) => item.id_course !== this.state.courseSelected.id_course)
                });
            }else{
                this.setState({
                    typeAlert: "error",
                    messageAlert: messageAlert !== undefined ? messageAlert : "Não foi possível deletar o Curso. Tente novamente!",
                    visibleAlert: true
                });
            }
        }
    }

    handleOpenEditCourseModal(course){
        this.setState({
            courseSelected: course,
            isEditCourseModalOpen: true
        });
    }

    handleCloseEditCourseModal(operation = false, successOperation = false, messageAlert = undefined){
        this.setState({isEditCourseModalOpen: false});
        if(operation === true){
            if(successOperation === true){
                this.setState({
                    typeAlert: "success",
                    messageAlert: "Dados do Curso atualizados com sucesso!",
                    visibleAlert: true
                });
                this.listCourses();
            }else{
                this.setState({
                    typeAlert: "error",
                    messageAlert: messageAlert !== undefined ? messageAlert : "Não foi possível atualizar os dados do Curso. Tente novamente!",
                    visibleAlert: true
                });
            }
        }
    }

    async listCourses(){
        try {
            const response = await api.get('/course');
            this.setState({courses: response.data.courses});    
        } catch (error) {
            console.log("Error!");
        }
    }

    async listAvas(){
        try {
            const response = await api.get('/ava');
            this.setState({ 
                avas: [{id_ava: 0, name_ava: "Todos os AVAs"}, ...response.data.avas],
                programs: [{id_program: 0, name_program: "Todos os Programas"}]
            });
        } catch (error) {
            this.setState({
                typeAlert: "error",
                messageAlert: "Não foi possível listar os os AVAs",
                visibleAlert: true
            });
        }
    }

    async onChangeAva(value){
        this.setState({ 
            avaSelected: value,
            programSelected: 0,
            programs: []
        });
        if(parseInt(value) !== 0){
            const response = await api.get(`/program/list/${parseInt(value)}`);
            console.log(response.data);
            this.setState({ programs: [{id_program: 0, name_program: "Todos os Programas do AVA"}, ...response.data.programs]});
        }   
    }

    async generateTestDocument(code_ava_course, id_ava, type = "Certificado"){
        this.setState({ loadingDocument: true, visibleAlert: false });

        let filename = "certificado_curso_teste.pdf";
        let endpoint = "/certificate/course";

        if (type === "Declaração") {
            filename = "declaracao_matricula_teste.pdf";
            endpoint = "/declaration";
        } else if (type === "Histórico") {
            filename = "historico_teste.pdf";
            endpoint = "/report";
        }

        try {
            const response = await api.get(
                `${endpoint}/test?id_ava=${id_ava}&code_ava_course=${code_ava_course}`, 
                { responseType: 'blob' }
            );

            const blob = new Blob([response.data], { type: 'application/pdf' });
            FileSaver.saveAs(blob, filename);
        } catch (error) {
            this.setState({
                typeAlert: "error",
                messageAlert: "Não foi possível fazer o Download do documento",
                visibleAlert: true
            });
        }
        this.setState({ loadingDocument: false });
    }
    
    render() {
        return (
            <div className="row m-0">

                <div className={this.state.visibleAlert === true ? "row m-0 area-alert" : "hide-area"}>    
                    <Alert type={this.state.typeAlert} message={this.state.messageAlert} closeAlert={this.closeAlert} />
                </div>

                <div className="navigation">
                    Configurações/ Cursos
                </div>
                <div className="row action-panel">
                    <div className="col-md-4 select">
                        <select className="form-select" onChange={(event) => this.onChangeAva(parseInt(event.target.value))}>
                            {this.state.avas.map((ava) => (
                                <option 
                                    className="select-options" 
                                    key={"ava"+ava.id_ava} 
                                    value={ava.id_ava}
                                >
                                    {ava.name_ava}
                                </option>
                            ))} 
                        </select>
                    </div>

                    <div className="col-md-4 select">
                        <select className="form-select" onChange={(event) => this.setState({ programSelected: parseInt(event.target.value)})}>
                            {this.state.programs.map((program) => (
                                <option 
                                    className="select-options"
                                    key={"program"+program.id_program} 
                                    value={program.id_program}
                                >
                                    {program.id_program !== 0 && program.Modality?.name_modality}
                                    {program.id_program === 0 && program.name_program}
                                </option>
                            ))} 
                        </select>
                    </div>
                    <div className="col-md-1"></div>
                    <div className="btn-area col-md-3">
                        <button className="btn btn-primary d-block" onClick={this.handleOpenNewCourseModal}>
                            + Novo Curso
                        </button>
                    </div>
                </div>
                
                <div className="row m-0">
                    <div className="table">
                        <table>
                            <thead>                
                                <tr className='titles'>
                                    <th>Curso</th>
                                    <th>Carga Horária</th>
                                    <th>Programa</th>
                                    <th>AVA</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.courses.map((course) => (
                                    ((this.state.avaSelected === 0) || 
                                        (this.state.avaSelected === course.Program.id_ava && this.state.programSelected === 0) || 
                                        (this.state.avaSelected === course.Program.id_ava && this.state.programSelected === course.id_program)) && 
                                        <tr className='rows' key={"course-id-"+course.id_course}>
                                            <td>{course.Modality.name_modality}</td>
                                            <td>{course.ch}</td>
                                            <td>{course.Program?.Modality.name_modality}</td>
                                            <td>{course.Program.Ava?.name_ava}</td>
                                            <td className="settings">
                                                <div className="settings-icons">
                                                    <span className="icon edit">
                                                        <CourseDocumentDownloadDropdown generateTestDocument={this.generateTestDocument} course={course} />
                                                    </span>
                                                    <span className="icon edit menu-dropdown" title="Editar Curso" onClick={() => this.handleOpenEditCourseModal(course)}>
                                                        <FaPencilAlt />
                                                    </span>
                                                    <span className="icon delete" title="Excluir Curso" onClick={() => this.handleOpenDeleteCourseModal(course)}>
                                                        <FaTrash />
                                                    </span>
                                                </div>
                                            </td>
                                        </tr>
                                ))} 
                            </tbody>
                        </table>    
                        {this.state.loadingDocument ? <Loader /> : null}
                    </div>
                    {this.state.isNewCourseModalOpen &&
                        <NewCourseModal
                            id="newCourseModal"
                            isOpen={this.state.isNewCourseModalOpen} 
                            onRequestClose={this.handleCloseNewCourseModal}
                            avas={this.state.avas.slice(1)}
                        />
                    }

                    {this.state.isDeleteCourseModalOpen &&
                        <DeleteCourseModal
                            id="deleteCourseModal"
                            isOpen={this.state.isDeleteCourseModalOpen} 
                            onRequestClose={this.handleCloseDeleteCourseModal}
                            course={this.state.courseSelected}
                        />
                    }

                    {this.state.isEditCourseModalOpen &&
                        <EditCourseModal
                            id="editCourseModal"
                            isOpen={this.state.isEditCourseModalOpen} 
                            onRequestClose={this.handleCloseEditCourseModal}
                            avas={this.state.avas.slice(1)}
                            course={this.state.courseSelected}
                        />
                    }
                </div>
            </div>
        );
    }
}

export default Courses;
