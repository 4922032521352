import jwt_decode from 'jwt-decode'


export const isAuthenticated = () =>{
    const now = (Date.now() / 1000)

    const tokenJSON = localStorage.getItem('@UNASUS:certification-token')
    
    if (tokenJSON !== null){
        const token = JSON.parse(tokenJSON)
        const exp = jwt_decode(token.data.token)

        //verifica se a sessão ainda não foi expirada
        if (exp.exp > now){ 
            return {status: true}
        }
        else{
            //desconecta o usuário removendo o token do LocalStorage
            localStorage.clear()
            return {status: false}
        }
    }else{
        return {
            status: false
        }
    }
}