import React from 'react';
import { api } from '../../../services/api';
import $ from 'jquery';

import './style.css';

class Logs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            logs: [],
            amount: 10,
            page: 1,
            maxItems: 999,
            maxPages: 999
        };

        this.updateLogCount = this.updateLogCount.bind(this);
        this.updatePage = this.updatePage.bind(this);
        this.updateLineAmount = this.updateLineAmount.bind(this);
    }

    componentDidMount() {
        this.updateLineAmount(10);
    }

    async updateLogCount() {
        try {
            const response = await api.post('/logs/log-count');
            this.setState({maxItems: response.data, maxPages: Math.ceil(response.data / this.state.amount)});
        } catch (e) {
            console.log(e);
            console.log("Erro: Falha na aquisição da contagem de linhas da tabela.");
            return;
        }
    }

    async updateLineAmount (new_amount) {
        if (!isNaN(new_amount) && new_amount > 0) {
            await this.updateLogCount();
            this.setState({amount: new_amount});
            await this.updatePage(this.state.page);
            $("#line-amount-input").val(new_amount);
        }
    }

    async updatePage(page_number) {
        if (page_number >= 1 && page_number <= this.state.maxPages) {
            await this.updateLogCount();
            try {
                const response = await api.post('/logs/list-latest', {
                    amount: this.state.amount,
                    page: page_number
                });
                this.setState({page: page_number, logs: response.data});
                $("#nav-input-button").val(page_number);
            } catch (e) {
                console.log(e);
                console.log(`Erro: Falha na aquisição da página ${page_number}.`);
            }
        }
    }

    render() {
        return (
            <div className="row m-0">
                <div className="navigation">
                    Sistema / Logs do Sistema
                </div>

                <div className="row m-0 line-amount">
                    <label>Linhas por página: <input id="line-amount-input" type="number" min="1" onChange={() => this.updateLineAmount($("#line-amount-input").val())}/></label>
                </div>

                <div className="row m-0">
                    <div className="table">
                        <table>
                            <thead>
                                <tr className='titles'>
                                    <th>N°</th>
                                    <th>Usuário</th>
                                    <th>Alteração</th>
                                    <th>ID</th>
                                    <th>Valor Anterior</th>
                                    <th>Valor Seguinte</th>
                                    <th>Data/Hora</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.logs.map((log, index) => (
                                    <tr className='rows' key={log.id_log}>
                                        <td>{log.id_log}</td>
                                        <td>{log.User.name_user}</td>
                                        <td>{log.LogAction.description}</td>
                                        <td>{log.id_target}</td>
                                        <td>{log.prev_value}</td>
                                        <td>{log.next_value}</td>
                                        <td>{(new Date(log.createdAt)).toLocaleDateString('pt-br', {
                                            year: 'numeric',
                                            month: '2-digit',
                                            day: '2-digit',
                                            second: '2-digit',
                                            minute: '2-digit',
                                            hour: '2-digit'
                                        })}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="button-nav-group">
                    <button type="button" onClick={() => {this.updatePage(1)}}>&lt;&lt;</button>
                    <button type="button" onClick={() => {
                        if (this.state.page > 1) {
                            this.updatePage(this.state.page - 1);
                        }
                    }}>&lt;</button>
                    <input id="nav-input-button" type="number" min="1" max={this.state.maxPages} onChange={() => {this.updatePage($("#nav-input-button").val())}}/>
                    <span>/</span>
                    <input type="number" value={this.state.maxPages} disabled />
                    <button type="button" onClick={() => {
                        if (this.state.page < this.state.maxPages) {
                            this.updatePage(this.state.page + 1);
                        }
                    }}>&gt;</button>
                    <button type="button" onClick={() => {this.updatePage(this.state.maxPages)}}>&gt;&gt;</button>
                </div>
            </div>
        );
    }
}

export default Logs;
