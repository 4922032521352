import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { api } from '../../../services/api';

import { AiOutlineClose } from "react-icons/ai";
import NewTemplateModal from '../../TemplateModal/NewTemplateModal';
import SelectTemplateModal from '../../TemplateModal/SelectTemplateModal';


function NewProgramModal ({isOpen, onRequestClose, avas}){
    const [btnBlocked, setBtnBlocked] = useState(true);

    const [nameProgram, setNameProgram] = useState('');
    const [idAva, setIdAva] = useState(0);
    const [codeProgram, setCodeProgram] = useState(0);
    const [avaPrograms, setAvaPrograms] = useState([]);
    const [quantCourses, setQuantCourses] = useState(0);
    const [startOffer, setStartOffer] = useState("");
    const [endOffer, setEndOffer] = useState("");
    const [templateChosen, setTemplateChosen] = useState({});

    const [isNewTemplateModalOpen, setIsNewTemplateModalOpen] = useState(false);
    const [isSelectTemplateModalOpen, setIsSelectTemplateModalOpen] = useState(false);

    useEffect(() => {
        if(nameProgram.length === 0 || idAva === 0 || codeProgram <= 0 || startOffer === "" || endOffer === ""){    
            setBtnBlocked(true);
        }else {
            setBtnBlocked(false);
        }
    }, [idAva, nameProgram, codeProgram, startOffer, endOffer]);

    useEffect(() => {
        async function searchPrograms(){
            const response = await api.post('external_data/programs', {
                id_ava: parseInt(idAva)
            });
            setAvaPrograms(response.data.programs);
        }

        setCodeProgram(0);
        setAvaPrograms([]);
        searchPrograms();
    }, [idAva]);

    async function createProgram(){
        try {
            await api.post('/program', {
                id_ava: parseInt(idAva),
                name_program: nameProgram,
                code_ava_program: codeProgram,
                start_offer_period: startOffer,
                end_offer_period: endOffer,
                id_template_c: templateChosen.id_template_c,
                quant_courses: quantCourses
            });
            onRequestClose(true, true);
        } catch (error) {
            let msg_error = undefined;
            
            if (error && error.response && error.response.data) {
                const errorData = error.response.data;
                if (typeof errorData.error === 'string') {
                  msg_error = errorData.error;
                }
            }

            onRequestClose(true, false, msg_error);
        }
    }

    function handleOpenNewTemplateModal(){
        setIsNewTemplateModalOpen(true);
    }

    function handleCloseNewTemplateModal(operation = false, successOperation = undefined, templateChosen = undefined){
        setIsNewTemplateModalOpen(false);
        if(operation === true){
            setTemplateChosen(templateChosen);
        }
    }

    function handleOpenSelectTemplateModal(){
        setIsSelectTemplateModalOpen(true);
    }

    function handleCloseSelectTemplateModal(operation = false, successOperation = undefined, templateChosen = undefined){
        setIsSelectTemplateModalOpen(false);
        if(operation === true){
            setTemplateChosen(templateChosen);
        }
    }

    return (
        <view>
            <Modal
                ariaHideApp={false}
                overlayClassName="react-modal-overlay"
                className="react-modal-content"
                isOpen={isOpen} 
                onRequestClose={onRequestClose}
            >
                <button 
                    className="react-modal-close"
                    onClick={onRequestClose} 
                >
                    <AiOutlineClose />
                </button>
                <div className="react-modal-title">
                    <h4 id="modal-title">Cadastro de Novo Programa</h4>
                </div>

                <div className="react-modal-body">
                    <div className="row modal-input-group">
                        <label htmlFor="programNameCreate" className="row">
                            <h5 className="col-md-3">Nome</h5>
                            <input 
                                type="text" maxLength={200} value={nameProgram} 
                                onChange={(event) => setNameProgram(event.target.value)}
                                id="programNameCreate" className="modal-input col-md-9" 
                            />
                        </label>
                    </div>

                    <div className="row modal-input-group">
                        <label htmlFor="programIdAvaCreate" className="row">
                            <h5 className="col-md-3">AVA</h5>
                            <select id="programIdAvaCreate" className="modal-input col-md-9" onChange={(event) => setIdAva(parseInt(event.target.value))}>
                                <option value={0}>Selecione um AVA</option>
                                {avas.map((ava) => (
                                    <option key={"ava"+ava.id_ava} value={ava.id_ava}>{ava.name_ava}</option>
                                ))} 
                            </select>
                        </label>
                    </div>

                    <div className="row modal-input-group">
                        <label htmlFor="programCodeCreate" className="row">
                            <h5 className="col-md-3">Programa no AVA</h5>
                            <select id="programCodeCreate" className="modal-input col-md-9" 
                                onChange={(event) => setCodeProgram(parseInt(event.target.value))}
                            >
                                <option value={0}>Selecione um Programa Equivalente</option>
                                {avaPrograms.map((avaProgram) => (
                                    <option key={"program"+avaProgram.code_ava_program} value={avaProgram.code_ava_program}>
                                        {avaProgram.name_program}
                                    </option>
                                ))} 
                            </select>
                        </label>
                    </div>
                    <div className="row modal-input-group">
                        <label htmlFor="quantCoursesCreate" className="row">
                            <h5 className="col-md-3">Quantidade de Cursos</h5>
                            <input 
                                type="number" value={quantCourses} 
                                onChange={(event) => setQuantCourses(event.target.value)} 
                                id="quantCoursesCreate" className="modal-input col-md-9" 
                            />
                        </label>
                    </div>

                    <div className="row modal-input-group">
                        <label htmlFor="programStartOffer" className="row">
                            <h5 className="col-md-3">Início da oferta</h5>
                            <input 
                                type="date" value={startOffer} 
                                onChange={(event) => setStartOffer(event.target.value)} 
                                id="programStartOffer" className="modal-input col-md-9" 
                            />
                        </label>
                    </div>

                    <div className="row modal-input-group">
                        <label htmlFor="programEndOffer" className="row">
                            <h5 className="col-md-3">Fim da oferta</h5>
                            <input 
                                type="date" value={endOffer} 
                                onChange={(event) => setEndOffer(event.target.value)} 
                                id="programEndOffer" className="modal-input col-md-9" 
                            />
                        </label>
                    </div>

                    <div className="row modal-input-group">
                        <label htmlFor="programTemplateCreate" className="row">
                            <h5 className="col-md-3">Template</h5>
                                <button className="btn btn-green" onClick={handleOpenNewTemplateModal}>
                                    Criar Novo Template
                                </button>
                                <button className="btn btn-blue" onClick={handleOpenSelectTemplateModal}>
                                    Buscar Template
                                </button>
                                {templateChosen.cover_template !== undefined && templateChosen.cover_template !== null &&
                                    <img src={process.env.REACT_APP_BACKEND_URL + '/' + templateChosen.cover_template} alt="Imagem de Capa do Template de Certificado" width="100px"></img>
                                }
                                {templateChosen.verse_template !== undefined && templateChosen.verse_template !== null &&
                                    <img src={process.env.REACT_APP_BACKEND_URL + '/' + templateChosen.verse_template} alt="Imagem de Verso do Template de Certificado" width="100px"></img>
                                }
                        </label>
                    </div>
                </div>
                <div className="react-modal-foot">
                    <div className="react-modal-btn">
                        <button className="btn btn-red" onClick={onRequestClose} >
                            Cancelar
                        </button>
                        <button 
                            className={btnBlocked ? "btn btn-blocked" : "btn btn-blue"} 
                            onClick={createProgram} disabled={btnBlocked}
                            type="button"
                        >
                            Cadastrar
                        </button>
                    </div>
                </div>
            </Modal>

            {isNewTemplateModalOpen &&
                <NewTemplateModal
                    id="newTemplateModal"
                    isOpen={isNewTemplateModalOpen} 
                    onRequestClose={handleCloseNewTemplateModal}
                />
            }

            {isSelectTemplateModalOpen &&
                <SelectTemplateModal
                    id="selectTemplateModal"
                    isOpen={isSelectTemplateModalOpen}
                    onRequestClose={handleCloseSelectTemplateModal}
                />
            }
        </view>
        
    );
    
}

export default NewProgramModal;