import React from 'react';
import Modal from 'react-modal';

import { api } from '../../../services/api';

import { AiOutlineClose } from "react-icons/ai";

function DeleteCourseModal ({isOpen, onRequestClose, course}){
    async function deleteCourse(){
        try {
            await api.delete(`/course/${course.id_course}`);
            onRequestClose(true, true);
        } catch (error) {
            let msg_error = undefined;
            
            if (error && error.response && error.response.data) {
                const errorData = error.response.data;
                if (typeof errorData.error === 'string') {
                  msg_error = errorData.error;
                }
            }

            onRequestClose(true, false, msg_error);
        }
    }

    return (
        <Modal
            ariaHideApp={false}
            overlayClassName="react-modal-overlay"
            className="react-modal-content react-modal-content-delete"
            isOpen={isOpen} 
            onRequestClose={onRequestClose}
        >
            <button 
                className="react-modal-close"
                onClick={onRequestClose} 
            >
                <AiOutlineClose />
            </button>
            <div className="react-modal-title">
                <h4 id="modal-title">Excluir Curso</h4>
            </div>

            <div className="react-modal-body">
                <span className="modal-text">
                    Tem certeza que deseja excluir o Curso {course.name_course}?
                </span>
                <div className="react-modal-btn">
                    <button className="btn btn-blue" onClick={onRequestClose}>
                        Cancelar
                    </button>
                    <button className="btn btn-red" onClick={deleteCourse}>
                        Excluir
                    </button>
                </div>
            </div>
        </Modal>
    );
}

export default DeleteCourseModal;