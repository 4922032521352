import axios from 'axios';

export const api = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
});

api.interceptors.request.use(async config => {
    const tokenJSON = localStorage.getItem('@UNASUS:certification-token')
  
    if (tokenJSON) {
      const token = JSON.parse(tokenJSON).data.token;
      //api.defaults.headers.authorization = `Bearer ${token}`;
      config.headers.Authorization = `Bearer ${token}`;
    }
  
    return config;
});  
