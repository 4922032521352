import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { PrivateRoute } from "./auth/PrivateRoute";
import Login from "./pages/Login";
import { Validation } from "./pages/Validation";
import Home from "./pages/Home";
//import Users from './pages/Users';
//import Courses from './pages/Courses';

/**
 * Contém as rotas da aplicação.
 * @returns Router
 */

export default function Routes() {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Validation} />
        <Route exact path="/login" component={Login} />
        <PrivateRoute exact path="/home" component={Home} />
      </Switch>
    </Router>
  );
}
