import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { api } from '../../../services/api';

import { AiOutlineClose } from "react-icons/ai";

function EditAvaModal ({isOpen, onRequestClose, ava}){
    const [btnBlocked, setBtnBlocked] = useState(true);

    const [nameAva, setNameAva] = useState(ava.name_ava);
    const [statusAva, setStatusAva] = useState(ava.status_ava);

    useEffect(() => {
        if(nameAva.length === 0){
            setBtnBlocked(true);
        }else {
            setBtnBlocked(false);
        }
    }, [nameAva]);

    async function updateAva(){
        try {
            await api.put('/ava/', {
                id_ava: ava.id_ava,
                name_ava: nameAva,
                status_ava: statusAva              
            });
            onRequestClose(true, true);
        } catch (error) {
            onRequestClose(true, false);
        }
    }

    return (
        <Modal
            ariaHideApp={false}
            overlayClassName="react-modal-overlay"
            className="react-modal-content"
            isOpen={isOpen} 
            onRequestClose={onRequestClose}
        >
            <button 
                className="react-modal-close"
                onClick={onRequestClose} 
            >
                <AiOutlineClose />
            </button>
            <div className="react-modal-title">
                <h4 id="modal-title">Atualizar os Dados do Ava</h4>
            </div>

            <div className="react-modal-body">
                <div className="row modal-input-group">
                    <label htmlFor="avaCodeEdit" className="row">
                        <h5 className="col-md-2">Código</h5>
                        <input 
                            type="number" value={ava.id_ava} 
                            id="avaCodeEdit" className="modal-input col-md-10 " 
                            disabled
                        />
                    </label>
                </div>

                <div className="row modal-input-group">
                    <label htmlFor="avaNameEdit" className="row">
                        <h5 className="col-md-2">Nome</h5>
                        <input 
                            type="text" maxLength={50} value={nameAva} 
                            onChange={(event) => setNameAva(event.target.value)}
                            id="avaNameEdit" className="modal-input col-md-10" 
                        />
                    </label>
                </div>

                <div className="row modal-input-group">
                    <label htmlFor="statusAvaEdit" className="row">
                        <h5 className="col-md-2">Status</h5>    
                        <select id="statusAvaEdit" className="modal-input col-md-10" onChange={(event) => setStatusAva(event.target.value)}>
                            <option value={true} selected={statusAva === true}>Ativo</option>
                            <option value={false} selected={statusAva === false}>Inativo</option>
                        </select>
                    </label>
                </div>
            </div>
            <div className="react-modal-foot">            
                <div className="react-modal-btn">
                    <button className="btn btn-red" onClick={onRequestClose} >
                        Cancelar
                    </button>
                    <button 
                        className={btnBlocked ? "btn btn-blocked" : "btn btn-blue"} 
                        onClick={updateAva} disabled={btnBlocked}
                    >
                        Atualizar
                    </button>
                </div>
            </div>
        </Modal>
    );
    
}

export default EditAvaModal;