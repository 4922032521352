import React from 'react';
import Courses from './Courses';
import Programs from './Programs';
import Students from './Students';
import Templates from './Templates';
import SchoolRecordTemplates from './SchoolRecordTemplates';
import DeclarationTemplates from './DeclarationTemplates';

class Configuration extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
        };
    }

    componentDidMount() {
       console.log("index - certification") 
    }

    render(){
      if (this.props.getSecondaryOption() === "cursos") {
        return <Courses />;
      } else if (this.props.getSecondaryOption() === "programas") {
        return <Programs />;
      } else if (this.props.getSecondaryOption() === "students") {
        return <Students />;
      } else if (this.props.getSecondaryOption() === "certificados") {
        return <Templates />;
      } else if (this.props.getSecondaryOption() === "templates_h") {
        return <SchoolRecordTemplates />;
      } else if (this.props.getSecondaryOption() === "templates_d") {
        return <DeclarationTemplates />;
      } else {
        return (
          <React.Fragment>
            <div className="main-home">
              <div className="row">
                <div className="column col-md-4">
                  <button
                    className="btn"
                    onClick={() => this.props.setSecondaryOption("templates_d")}
                  >
                    <div className="d-flex align-items-center">
                      <div className="col-md-2 p-0">
                        <i className="far fa-edit"></i>
                      </div>
                      <div className="col-md-10 text">
                        Templates de Declaração de Matrícula
                      </div>
                    </div>
                  </button>
                </div>
    
                <div className="column col-md-4">
                  <button
                    className="btn"
                    onClick={() => this.props.setSecondaryOption("templates_h")}
                  >
                    <div className="d-flex align-items-center">
                      <div className="col-md-2 p-0">
                        <i className="far fa-edit"></i>
                      </div>
                      <div className="col-md-10 text">
                        Templates de Histórico de Curso
                      </div>
                    </div>
                  </button>
                </div>
    
                <div className="column col-md-4">
                  <button
                    className="btn"
                    onClick={() => this.props.setSecondaryOption("certificados")}
                  >
                    <div className="d-flex align-items-center">
                      <div className="col-md-2 p-0">
                        <i className="far fa-edit"></i>
                      </div>
                      <div className="col-md-10 text">
                        Templates de Certificados
                      </div>
                    </div>
                  </button>
                </div>
              </div>
    
              <div className="row">
                <div className="column col-md-4">
                  <button
                    className="btn"
                    onClick={() => this.props.setSecondaryOption("programas")}
                  >
                    <div className="d-flex align-items-center">
                      <div className="col-md-3 p-0">
                        <i className="fas fa-cogs"></i>
                      </div>
                      <div className="col-md-9 text">Programas</div>
                    </div>
                  </button>
                </div>
    
                <div className="column col-md-4">
                  <button
                    className="btn"
                    onClick={() => this.props.setSecondaryOption("cursos")}
                  >
                    <div className="d-flex align-items-center">
                      <div className="col-md-3 p-0">
                        <i className="fas fa-cogs"></i>
                      </div>
                      <div className="col-md-9 text">Cursos</div>
                    </div>
                  </button>
                </div>
    
                <div className="column col-md-4">
                  <button
                    className="btn"
                    onClick={() => this.props.setSecondaryOption("students")}
                  >
                    <div className="d-flex align-items-center">
                      <div className="col-md-3 p-0">
                        <i className="fas fa-cogs"></i>
                      </div>
                      <div className="col-md-9 text">Alunos</div>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </React.Fragment>
        );
      }
    }
}


export default Configuration;
