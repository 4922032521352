import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { api } from '../../../services/api';

import { AiOutlineClose } from "react-icons/ai";

function AvaCredentialModal ({isOpen, onRequestClose, ava}){
    const [btnBlocked, setBtnBlocked] = useState(true);

    const [dbHost, setDbHost] = useState('');
    const [dbName, setDbName] = useState('');
    const [dbUser, setDbUser] = useState('');
    const [dbPassword, setDbPassword] = useState('');
    const [dbPort, setDbPort] = useState(0);

    useEffect(() => {
        if(dbHost === '' || dbName === '' || dbUser === '' || dbPassword === '' || dbPort === ''){
            setBtnBlocked(true);
        }else {
            setBtnBlocked(false);
        }
    }, [dbHost, dbName, dbUser, dbPassword, dbPort]);

    useEffect(() => {
        async function getCredentials(){
            try {
                const response = await api.get(`/ava/credentials/${ava.id_ava}`);
                setDbName(response.data.ava.db_name);
                setDbUser(response.data.ava.db_user);
                setDbPort(response.data.ava.db_port);
            } catch (error) {
                let msg_error = undefined;
            
                if (error && error.response && error.response.data) {
                    const errorData = error.response.data;
                    if (typeof errorData.error === 'string') {
                    msg_error = errorData.error;
                    }
                }

                onRequestClose(true, false, msg_error);
            }
        }
        getCredentials();
    }, []);

    async function createAva(){
        try {
            await api.put('/ava/credentials', {
                id_ava: ava.id_ava,
                db_host: dbHost,
                db_name: dbName,
                db_user: dbUser,
                db_password: dbPassword,
                db_port: dbPort
            });
            onRequestClose(true, true);
        } catch (error) {
            let msg_error = undefined;
            
            if (error && error.response && error.response.data) {
                const errorData = error.response.data;
                if (typeof errorData.error === 'string') {
                  msg_error = errorData.error;
                }
            }

            onRequestClose(true, false, msg_error);
        }
    }

    return (
        <Modal
            ariaHideApp={false}
            overlayClassName="react-modal-overlay"
            className="react-modal-content"
            isOpen={isOpen} 
            onRequestClose={onRequestClose}
        >
            <button 
                className="react-modal-close"
                onClick={onRequestClose} 
            >
                <AiOutlineClose />
            </button>
            <div className="react-modal-title">
                <h4 id="modal-title">Atualizar Credenciais do Banco de Dados</h4>
            </div>

            <div className="react-modal-body">
                <div className="row modal-input-group">
                    <label htmlFor="avaNameCreate" className="row">
                        <h5 className="col-md-5">Nome do AVA</h5>
                        <input 
                            type="text" value={ava.name_ava} disabled
                            id="avaNameCreate" className="modal-input col-md-7" 
                        />
                    </label>
                </div>

                <div className="row modal-input-group">
                    <label htmlFor="dbHostCreate" className="row">
                        <h5 className="col-md-5">Host do Banco de Dados</h5>
                        <input 
                            type="text" maxLength={50} value={dbHost} 
                            onChange={(event) => setDbHost(event.target.value)}
                            id="dbHostCreate" className="modal-input col-md-7" 
                        />
                    </label>
                </div>

                <div className="row modal-input-group">
                    <label htmlFor="dbNameCreate" className="row">
                        <h5 className="col-md-5">Nome do Banco de Dados</h5>
                        <input 
                            type="text" maxLength={50} value={dbName} 
                            onChange={(event) => setDbName(event.target.value)}
                            id="dbNameCreate" className="modal-input col-md-7" 
                        />
                    </label>
                </div>

                <div className="row modal-input-group">
                    <label htmlFor="dbUserCreate" className="row">
                        <h5 className="col-md-5">Usuário do Banco de Dados</h5>
                        <input 
                            type="text" maxLength={50} value={dbUser} 
                            onChange={(event) => setDbUser(event.target.value)}
                            id="dbUserCreate" className="modal-input col-md-7" 
                        />
                    </label>
                </div>

                <div className="row modal-input-group">
                    <label htmlFor="dbPasswordCreate" className="row">
                        <h5 className="col-md-5">Senha do Banco de Dados</h5>
                        <input 
                            type="text" maxLength={50} value={dbPassword} 
                            onChange={(event) => setDbPassword(event.target.value)}
                            id="dbPasswordCreate" className="modal-input col-md-7" 
                        />
                    </label>
                </div>

                <div className="row modal-input-group">
                    <label htmlFor="dbPortCreate" className="row">
                        <h5 className="col-md-5">Porta do Banco de Dados</h5>
                        <input 
                            type="number" maxLength={10} value={dbPort} 
                            onChange={(event) => setDbPort(event.target.value)}
                            id="dbPortCreate" className="modal-input col-md-7" 
                        />
                    </label>
                </div>
            </div>
            <div className="react-modal-foot">            
                <div className="react-modal-btn">
                    <button className="btn btn-red" onClick={onRequestClose} >
                        Cancelar
                    </button>
                    <button 
                        className={btnBlocked ? "btn btn-blocked" : "btn btn-blue"} 
                        onClick={createAva} disabled={btnBlocked}
                        type="button"
                    >
                        Atualizar
                    </button>
                </div>
            </div>
        </Modal>
    );
    
}

export default AvaCredentialModal;