import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { api } from '../../../services/api';

import { AiOutlineClose } from "react-icons/ai";

function NewTemplateModal ({isOpen, onRequestClose}){
    const [btnBlocked, setBtnBlocked] = useState(true);

    const [nameTemplate, setNameTemplate] = useState('');
    const [message, setMessage] = useState('');
    const [coverCertificate, setCoverCertificate] = useState();
    const [verseCertificate, setVerseCertificate] = useState();
    const [color1, setColor1] = useState('#000000');
    const [color2, setColor2] = useState('#000000');
    const [color3, setColor3] = useState('#000000');
    const [color4, setColor4] = useState('#000000');
    const [messageLayout, setMessageLayout] = useState(1);

    useEffect(() => {
        if(message === '' || coverCertificate === null || nameTemplate === ''){
            setBtnBlocked(true);
        }else {
            setBtnBlocked(false);
        }
    }, [message, coverCertificate, nameTemplate]);

    async function createTemplate(){
        try {
            var formData = new FormData();
            formData.append("message", message);
            formData.append("files", coverCertificate);
            formData.append("files", verseCertificate);
            formData.append("name", nameTemplate);
            formData.append("message_layout", messageLayout);
            formData.append("colors", [color1, color2, color3, color4].join(';'));

            var config = {
                headers: {
                    'Content-Type': "multipart/form-data; boundary='files'"
                }
            };
            
            await api.post('/templatec', formData,  config)
                .catch(function  (error){
                    console.log(error.response);
                }).then(data => {
                    onRequestClose(true, true, data.data.template);
                });
            
        } catch (error) {
            let msg_error = undefined;
            
            if (error && error.response && error.response.data) {
                const errorData = error.response.data;
                if (typeof errorData.error === 'string') {
                  msg_error = errorData.error;
                }
            }

            onRequestClose(true, false, msg_error);
        }

    }

    return (
        <Modal
            ariaHideApp={false}
            overlayClassName="react-modal-overlay"
            className="react-modal-content"
            isOpen={isOpen} 
            onRequestClose={onRequestClose}
        >
            <button 
                className="react-modal-close"
                onClick={onRequestClose} 
            >
                <AiOutlineClose />
            </button>
            <div className="react-modal-title">
                <h4 id="modal-title">Cadastrar Template de Certificado</h4>
            </div>

            <div className="react-modal-body">
                <div className="row modal-input-group">
                    <label htmlFor="nameCreate" className="row">
                        <h5 className="col-md-2">Nome</h5>
                        <input 
                            type="text" maxLength={50} value={nameTemplate} 
                            onChange={(event) => setNameTemplate(event.target.value)}
                            id="nameCreate" className="modal-input col-md-10" 
                        />
                    </label>
                </div>
                <div className="row modal-input-group">
                    <label htmlFor="messageCreate" className="row">
                        <h5 className="col-md-2">Mensagem</h5>
                        <textarea 
                            maxLength={500} value={message} 
                            onChange={(event) => setMessage(event.target.value)}
                            id="messageCreate" className="modal-input col-md-10" 
                        />
                    </label>
                </div>
                <div className="row modal-input-group">
                    <label htmlFor="messageLayoutTemplate" className="row">
                        <h5 className="col-md-2">Layout da Mensagem</h5>
                        <select id="messageLayoutTemplate" className="modal-input col-md-10"
                            onChange={(event) => setMessageLayout(parseInt(event.target.value))}
                        >
                            <option value={1}>Layout Horizontal</option>
                            <option value={2}>Layout Tradicional</option>
                        </select>
                    </label>
                </div>
                <div className="row modal-input-group">
                    <label htmlFor="certificateCreate" className="row">
                        <h5 className="col-md-2">Capa</h5>
                        <input 
                            type="file" id="certificateCreate" className="modal-input col-md-7" 
                            accept=".png,.jpeg,.jpg" onChange={(event) => {
                                const fileCover = event.target.files[0];
                                setCoverCertificate(fileCover);
                            }}
                        />
                        {coverCertificate !== undefined &&
                            <img src={URL.createObjectURL(coverCertificate)} alt="Imagem de Capa do Template de Certificado" width="200"></img>
                        }
                    </label>
                </div>
                <div className="row modal-input-group">
                    <label htmlFor="historyCreate" className="row">
                        <h5 className="col-md-2">Verso</h5>
                        <input 
                            type="file" id="historyCreate" className="modal-input col-md-7"
                            accept=".png,.jpeg,.jpg" onChange={(event) => {
                                const fileVerse = event.target.files[0];
                                setVerseCertificate(fileVerse);
                            }} 
                        />
                        {verseCertificate !== undefined &&
                            <img src={URL.createObjectURL(verseCertificate)} alt="Imagem de Verso do Template de Certificado" width="200"></img>
                        }
                    </label>
                </div>
                <div className="row modal-input-group">
                    <label htmlFor="colorsCreate" className="row">
                        <h5 className="col-md-2">Cores da Tabela</h5>
                        <input 
                            type="color" id="colorsCreate" className="modal-input col-md-2" title="Cor do título"
                            value={color1} onChange={(event) => setColor1(event.target.value)}
                        />
                        <input 
                            type="color" id="colorsCreate" className="modal-input col-md-2" title="Cor do cabeçalho" 
                            value={color2} onChange={(event) => setColor2(event.target.value)}
                        />
                        <input 
                            type="color" id="colorsCreate" className="modal-input col-md-2" title="Cor do conteúdo"
                            value={color3} onChange={(event) => setColor3(event.target.value)}
                        />
                        <input 
                            type="color" id="colorsCreate" className="modal-input col-md-2" title="Cor do rodapé"
                            value={color4} onChange={(event) => setColor4(event.target.value)}
                        />
                    </label>
                </div>
            </div>
            <div className="react-modal-foot">
                <div className="react-modal-btn">
                    <button className="btn btn-red" onClick={onRequestClose} >
                        Cancelar
                    </button>
                    <button 
                        className={btnBlocked ? "btn btn-blocked" : "btn btn-blue"} 
                        onClick={createTemplate} disabled={btnBlocked}
                        type="button"
                    >
                        Cadastrar
                    </button>
                </div>
            </div>
        </Modal>
    );
    
}

export default NewTemplateModal;